// var buttonSize = 42

var fontColor = "#001021";
var buttonSize = 42;

var colors = {
  alternatingBackground: "rgba(0,0,20,0.03)",
  button: "#5723E7",
  button_hover: "#400FC8",
  yellow: "#FFEA00",
  teal: "#3CD782",
  pink: "#EC1181",
  dark: "#1d2228",
  grey: "#F4F5F8",
  darkBlue: "#006BFF",
  lightBlue: "#4ACDFF",
  font: fontColor,
  fontLight: "rgba(0,16,33,0.33)",
  lightGrey: "rgba(0,16,33,0.12)",
  lightLightGrey: "rgba(0,16,33,0.06)",
  border: "rgba(0,16,33,0.04)",
  // pink: '#FD297B',
  orange: "#FF5864",
  // button: '#00D2FF',
  // purple: '#4A00E0',
  purple: "#9E53FF",
  backgroundGrey: "#EBEFF6",
  snapchat: "#FFFC00",
  youtube: "#e52d27",
  tiktok: "#1d1d1d",
  transparentBlack: "rgba(0,0,0,0.20)",
  backgroundDark: "#06070D",
  green: "#29F599",
  volt: "#C5FF00",
  utr: "#29BAB6",
};

var style_light = {
  // accent:"#DC1358",
  hover: "rgb(248,248,248)",
  hoverTab: "rgb(222,222,224)",
  accent: "#30DCA1", //green
  // accent:"#DE0062", //red
  cardColor: "white",
  screenBackgroundColor: "#FAFBFB",
  backgroundColor: "rgb(250,250,250)",
  featured: "#151213",
  secondaryColor: "#F5F5FA",
  borderColor: "rgba(232,232,232,1)",
  borderColorSecondary: "rgb(245,245,245)",
  background_alternating: "rgba(0,0,0,0.03)",
  headerColor: "white",
  header_backgroundColor: "white",
  header_color: "black",
  header: {
    color: "#08080A",
    backgroundColor: "#FFF",
  },
  section_title: {
    // color:'#08080A',
    // background:'#F5F5FA',
    color: "white",
    background: "#121212",
  },
  section_subtitle: {
    color: "black",
    background: "white",
  },
  color: "#08080A",
  color_secondary: "rgb(150,150,150)",
  ball: "#A0FA0A",
  streaming: "#FA0A6C",
  blurType: "dark",
  isDark: false,
  button: {
    background: "#1A1A1A",
    color: "white",
  },
  navbar: {
    background: "white",
  },
  media: {
    background: "white",
  },

  navIcon: {
    active: "#232327",
    inactive: "#9D9EA8",
  },
  dateBorder: "rgba(0,0,0,0.2)",
  dropdown: "#E8EBF4",

  collapsibleHeader: {
    gradient: ["#0F151E", "#151E2B"],
    color: "white",
    borderColor: "rgba(255,255,255,0.1)",
  },

  content: {
    backgroundColor: "#EAECEF",
    // backgroundColor:'#EDF1F6',
    header: "rgba(0,0,10,0)",
    card: "#FFF",
    secondary: "#FFF",
    borderWidth: 1,
    borderColor: "rgba(0,0,0,0.1)",
    cardOpaque: "rgba(255,255,255,0)",
    match: "#FFF",
  },

  //Core
  backgroundColor: "#FBFBFF",
  color: "#000",
  colorSecondary: "#AAB0B4",
  color_secondary: "#AAB0B4",

  // borderColor:"#F3F3F3",
  borderColor: "#EAECF2",
  bracket_color: "#999999",
  chart_line_color: "#CACBD2",

  section_title: {
    color: "#00131E",
    background: "#F0F2F8",
    backgroundColor: "#F0F2F8",
  },

  //Main header
  header: {
    color: "#FFF",
    // backgroundColor: '#141E2C',
    backgroundColor: "#12141C",
    colorInactive: "rgba(255,255,255,0.5)",
    backgroundReducedOpacity: "rgba(255,255,255,0.14)",
    borderColor: "rgba(255,255,255,0.075)",
  },
  //CTA
  cta: {
    // backgroundColor:"#98E400",
    // color:'black',
    backgroundColor: "#9B80FF",
    color: "#FBFBFF",
  },

  headerSecondary: {
    color: "#FFF",
    backgroundColor: "#000F1E",
  },

  ctaSecondary: {
    backgroundColor: "#02BADE",
    color: "#0D0D10",
  },

  backgroundColorOpaque: "rgba(13,13,16,0)",
  secondaryColor: "#664DC9",
  background_alternating: "#F8F8F8",

  // color_secondary:'rgba(255,255,255,0.67)',
  ball: "#89CA00",
  streaming: "#A0FA0A",
  blurType: "light",
  isDark: false,
  statusBar: "light-content",
  separator: "rgba(0,0,0,0.9)",
  color_trivia: "#C8FF6A",
};

var style_dark = {
  cardColor: "#1D1D1F",
  accent: "#30DCA1",
  gradient: ["#121212", "#0C0C0C"],
  alternatingBackground: "rgba(255,255,255,0.04)",
  backgroundColor: "#151213",
  featured: "rgba(255,255,255,0.04)",
  bg_opacity: 0.14,
  color: "white",
  color_secondary: "rgba(255,255,255,0.67)",
  section_title: {
    color: "white",
    // background: "#2F096B",
    background:"#2F2F33"
  },
  section_subtitle: {
    color: "black",
    background: "white",
  },
  ball: "#A0FA0A",
  color_font: "white",
  color_searchbar: "#E8E8EB",
  color_card: "#1D1D1F",
  color_card_side: "#181819",
  borderColor: "rgba(255,255,255,0.04)",
  color_accent_1: "#20057E",
  // color_accent_1:colors.purple,
  color_accent_2: colors.volt,
  color_font_accent: "white",
  color_tab_main: fontColor,
  color_tab_font_main: "black",
  color_tab_secondary: "rgba(255,255,255,0.1)",
  color_tab_font_secondary: fontColor,

  navIcon: {
    active: "#E0E0EA",
    inactive: "#6B6C77",
  },
  dropdown: "#17181D",
  dateBorder: "rgba(255,255,255,0.2)",
  //Core
  backgroundColor: "#0D0D10",
  color: "#fff",
  colorSecondary: "#868688",
  color_secondary: "#868688",

  content: {
    // backgroundColor:'#0D0D10',
    // backgroundColor:'#09090B',

    // card:'#17181D',
    // card:'#111217',

    header: "rgba(0,0,10,0)",

    // //Updated
    backgroundColor: "#09090B",
    match: "#101015",
    card: "#1D1D20",
    secondary: "#1D1D20",

    //Fotmob
    // backgroundColor:'#000',
    // card:'#1D1D1D',
    // secondary:'#1D1D1D',

    // card:"#1C1C1E",
    // header:'#121317',
    // secondary:'#0C0D11',
    cardOpaque: "rgba(23,24,29,0)",
    borderColor: "rgba(255,255,255,0.1)",
  },

  // borderColor:"#1B1B1E",
  borderColor: "#1A1A1D",

  bracket_color: "#5D5D5F",
  chart_line_color: "#404043",

  //Lists
  section_title: {
    color: "#FFF",
    background: "#2F2F33",
    backgroundColor: "#2F2F33",
  },

  collapsibleHeader: {
    gradient: ["#0D0D10", "#17181D"],
    color: "#FFF",
    borderColor: "rgba(255,255,255,0.1)",
  },

  //Main header
  header: {
    color: "#FFF",
    colorInactive: "rgba(255,255,255,0.5)",
    backgroundColor: "#12171C",
    backgroundReducedOpacity: "rgba(255,255,255,0.05)",
    borderColor: "rgba(255,255,255,0.1)",
  },

  headerSecondary: {
    color: "#FFF",
    backgroundColor: "#12171C",
  },

  //CTA
  cta: {
    // backgroundColor:"#98E400",
    // color:'black',
    backgroundColor: "#9B80FF",
    color: "#FBFBFF",
  },

  ctaSecondary: {
    backgroundColor: "#03AB80",
    color: "#FFF",
  },

  backgroundColorOpaque: "rgba(13,13,16,0)",
  secondaryColor: "#664DC9",
  background_alternating: "#151517",
  // section_title:{
  //   color:'#FFF',
  //   background:'#170068',
  // },

  // color_secondary:'rgba(255,255,255,0.67)',
  ball: "#A0FA0A",
  streaming: "#A0FA0A",
  blurType: "light",
  isDark: true,
  statusBar: "light-content",
  separator: "rgba(0,0,0,0.9)",
  color_trivia: "#C8FF6A",
};

var GLOBAL = {
  logos: {
    atp: "https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/logos%2Fatplogotnns.webp?alt=media&token=64e022c3-291d-4d72-9c5c-9ab576bb2309",
    wta: "https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/logos%2Fwtalogotnns.webp?alt=media&token=8f4c5bfe-47bf-4f83-bdef-123996b63c2e",
    challenger:
      "https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/logos%2Fatpchallenger-text.webp?alt=media&token=73174ae5-eea9-4fd3-9985-9182d4eaa9a2",
    itf: "https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/misc%2Fitflogo.webp?alt=media&token=9491fc34-92b7-432d-a4af-649607135377",
  },
  column_width: 340,
  button_height: 44,
  page: "Home",
  padding: 16,
  matchCardStyle_completed: "visual",
  matchCardStyle_scheduled: "visual",
  matchCardStyle_live: "visual",
  tournamentsObj: {},
  maxWidth: 840,
  spacing: 20,
  spacing_card: 30,
  borderRadius: 8,
  logo: "https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/web%2Flogo-light.png?alt=media&token=0bb755a9-6535-44d4-acb4-afe4229eb1c9",
  style_light: {
    gradient: ["white", "rgba(245,245,255,1)"],
    alternatingBackground: colors.alternatingBackground,
    backgroundColor: "white",
    bg_opacity: 0.32,
    color_font: "#121212",
    color_searchbar: "#E8E8EB",
    color_card: "#E5E6EE",
    color_card_side: "#F2F2F7",
    color_border: "rgba(0,0,0,0.04)",
    color_accent_1: "#20057E",
    color_accent_2: colors.volt,
    color_font_accent: "white",
    color_tab_main: fontColor,
    color_tab_font_main: "black",
    color_tab_secondary: "rgba(255,255,255,0.1)",
    color_tab_font_secondary: fontColor,
  },
  colors,
  darkMode: true,
  // style:style_light,
  style: style_dark,
  style_light,
  style_dark,
  // fonts:{
  //   ultra:'ClanOT-Ultra',
  //   black:'ClanOT-Black',
  //   bold:'ClanOT-Bold',
  //   medium:'ClanOT-Medium',
  //   regular:'ClanOT-News',
  //   // thin:'ClanOT-Thin',
  // },
  fonts: {
    ultra: "Inter-Black",
    black: "Inter-Black",
    bold: "Inter-Bold",
    medium: "Inter-Semibold",
    regular: "Inter-Regular",
    thin: "Inter-Light",
  },
  flag_corrections: {
    GBR: "GB",
  },
  // fonts:{
  //  regular:'Inter-Regular',
  //  bold:'Inter-Bold',
  //  semibold:'Inter-Semibold',
  //  medium:'Inter-Semibold',
  //  black:'Inter-Black',
  // },
  styles: {
    tabView: {
      borderRadius: 800,
      paddingHorizontal: 16,
      paddingVertical: 8,
      marginRight: 0,
    },
    tabViewActive: { backgroundColor: "white" },
    tabText: {
      fontFamily: "Roboto-Regular",
      color: "rgba(255,255,255,0.67)",
      fontSize: 16,
      letterSpacing: -0.3,
    },
    tabTextActive: { color: fontColor, fontFamily: "Roboto-Bold" },
  },
  shadows: {
    text: {
      textShadowOffset: { width: 0, height: 1 },
      textShadowRadius: 4,
      textShadowColor: "rgba(0,16,33,0.20)",
    },
    view: {
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.06,
      shadowRadius: 3.84,
    },
  },
};

export default GLOBAL;
