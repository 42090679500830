import React, { PureComponent } from "react";
import { TextInput } from 'react-native'
import { useDebouncedCallback } from 'use-debounce';

const TextInputDebounced = (props) => {
  
  // Debounce callback
  const onChangeText = (text) => {
    if(props.onChangeText) props.onChangeText(text)
    debounced(text)
  }

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      if(props.onFinishEditing){
        props.onFinishEditing(value);
      }else{
        console.error('No onFinishEditing provided for TextInput debounce ')
      }
    },
    // delay in ms
    350
  );

  // you should use `e => debounced(e.target.value)` as react works with synthetic events
  return <TextInput {...props} onChangeText={onChangeText} />
}

export default TextInputDebounced