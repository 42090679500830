/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  SectionList,
  Pressable,
  FlatList,
  Image,
  Dimensions,
} from 'react-native';

//Modules
import { Hoverable } from "react-native-web-hover";

//Components
import Loading from '../../components/general/loading.js'
import Tabs from '../../components/general/tabs.js'
import EmptyState from '../../components/general/emptystate.js'
import SectionHeader from '../../components/general/sectionheader.js'

//Helpers
import useList from '../../helpers/useList.js';
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

const dimensions = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
}

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      tour:'tour',
      submode:'active',
      loading:true,
    }
  }

  async componentDidMount(){
    setTimeout(()=>{
      this.setup()
    },350)
  }

  async componentWillUnmount(){

  }

  changeSubmode = async (data) => {
    var submode = data.id
    await this.setState({submode})
    this.setup({submode})
  }

  changeTour = async (tour_data) => {
    var tour = tour_data.id
    await this.setState({tour})
    this.setup({tour})
  }

  setup = async (params, forceRefresh) => {

    // this.setState({refreshing:true})
    const displayList = useList()

    if(!params){params = {}}

    params.mode = 'tournaments'
    params.versionNumber = 600
    // params.submode = this.props.submode

    if(!params.tour){params.tour = this.state.tour}
    if(!params.submode){params.submode = this.state.submode}

    // if(params.tour === 'challenger') params.tour = 'Challenger'

    var cache_key = 'tournaments/'+params.tour+"/"+params.submode
    var cache_used = false


    if(!cache_used){
      this.setState({refreshing:true})
    }

    var data_r = await Functions.tnnsAPI(params)
    var data = data_r?.data || {}
    var { sections } = data

    // console.log(sections)

    this.setState({sections, loading:false, refreshing:false, displayList})

    //Update cache
    // await Functions.updateCache(cache_key, sections)
  }

  openTournament = (item) => {
    // item.id = "OIKcZx69FslB1Bvz9RqT"
    if(GLOBAL.navigation){
      GLOBAL.navigation.push('Tournament', {id:item.key})
    }else{
      Functions.openURL("https://www.tnnslive.com/tournament/"+item.key)
      // GLOBAL.navigation.push('Tournament', {id:item.id})
    }
  }

  //
  renderContent = () => {
    var { sections } = this.state
    if(this.state.displayList){
      return(
        <View style={{width:'100%', flex:1}}>
          <SectionList
            style={{width:'100%', flex:1}}
            contentContainerStyle={{width:'100%', paddingBottom:0}}
            sections={sections || []}
            keyExtractor={(item, index) => index}
            renderItem={this.renderItem}
            renderSectionHeader={this.renderSectionHeader}
            ListEmptyComponent={this.renderEmpty}
            stickySectionHeadersEnabled
          />
        </View>
      )
    }
    return(
      <View style={{width:'100%', flex:1, borderColor:GLOBAL.style.borderColor}}>
        <SectionList
          style={{width:'100%', flex:1}}
          contentContainerStyle={{width:'100%', paddingBottom:0}}
          sections={sections || []}
          keyExtractor={(item, index) => index}
          renderItem={this.renderItem}
          renderSectionHeader={this.renderSectionHeader}
          ListEmptyComponent={this.renderEmpty}
        />
      </View>
    )
  }

  renderEmpty = () => {
    return <EmptyState string="There are no tournaments to display"/>
  }

  renderItem = ({item, index}) => {
    var backgroundColor = 'transparent'

    var size = 48
    var height = 98

    var logo_width = 72
    if(logo_width > 100){logo_width = 100}

    return(
      <Hoverable>
      {({ hovered }) => (
        <Pressable onPress={()=>this.openTournament(item)} style={[{paddingVertical:12, width:'100%', paddingHorizontal:GLOBAL.padding, backgroundColor:hovered ? GLOBAL.style.dropdown : 'transparent', borderTopColor:GLOBAL.style.content.borderColor, borderTopWidth:index > 0 ? 1 : 0}, MainStyles.flexRow, MainStyles.flexStartCenter]}>

          <View style={[{flex:1}, MainStyles.flexCenterStart]}>
            <Text style={{fontFamily:GLOBAL.fonts.bold, color:GLOBAL.style.color, fontSize:14}} numberOfLines={1}>
            {item.title || item.t}
            </Text>

            <Text style={{marginTop:GLOBAL.padding / 2, fontSize:12, color:GLOBAL.style.color, opacity:0.67, fontFamily:GLOBAL.fonts.regular}}>
            {item.subtitle?.text}
            </Text>

            {
              item.metadata?.length && item.metadata.map(obj => (
                <Text style={{marginTop:GLOBAL.padding / 2, fontSize:12, color:GLOBAL.style.color, opacity:0.67, fontFamily:GLOBAL.fonts.regular}}>
                {obj.text}
                </Text>
              ))
              || null
            }
          </View>

          {
            item?.logos?.length === 0 ? null :
            <View style={[{marginLeft:GLOBAL.padding, width:(logo_width+GLOBAL.padding), minHeight:logo_width, borderLeftWidth:1, borderLeftColor:GLOBAL.style.borderColor}, MainStyles.flexCenterEnd]}>
              {item?.logos?.map((item2, index2) => this.renderItem_logo(item2, index2, logo_width))}
            </View>
          }

        </Pressable>
      )}
      </Hoverable>
    )
  }

  renderItem_logo = (item, index, logo_size) => {
    var marginTop = index > 0 ? GLOBAL.padding / 4 : 0
    var scale = item.scale || 1
    var size = logo_size * scale
    return(
      <Image
      key={index}
      style={[{height:item.height || size*(1/item.aspectRatio || 1), width:item.width || size, maxHeight:(size*0.35), marginVertical:4, resizeMode:'contain'}]}
      source={{uri:item.url}}
      />
    )
  }

  renderSectionHeader = ({section}) => {
    var { title } = section
    return(
      <SectionHeader title={title} list={this.state.displayList}/>
    )
  }

  renderTabs = () => {
    var modes = [
      {id:'tour', title:'ATP & WTA'},
      {id:'Challenger', title:'Challenger'},
      {id:'WTA 125K', title:'WTA 125K'},
      {id:'ITF', title:'ITF'},
      {id:'UTR', title:'UTR'},
      // {id:'other', title:'Other'},
    ]
    return <Tabs tabs={modes} onChange={this.changeTour} active_tab={this.state.tour} secondary/>
  }

  //
  renderModes = () => {
    var tours = [
      {id:'active', title:'Active Now', subtitle:'Tournaments in play'},
      {id:'season', title:'Season', subtitle:'All tournaments this season'},
    ]

    if(this.props.hideCalendar){
      tours = [
        {id:'active', title:'Active Tournaments', subtitle:'Tournaments in play'},
      ]
    }


    var modes = [
      {id:'tour', title:'ATP & WTA'},
      {id:'challenger', title:'Challenger'},
      {id:'itf', title:'ITF'},
      // {id:'other', title:'Other'},
    ]

    // return(
    //   <FlatList
    //   style={{width:'100%', borderBottomColor:GLOBAL.style.borderColor, borderBottomWidth:1, paddingVertical:0, backgroundColor:GLOBAL.style.cardColor}}
    //   contentContainerStyle={{paddingHorizontal:0}}
    //   data={tours}
    //   renderItem={this.renderMode}
    //   horizontal
    //   />
    // )

    return <Tabs tabs={tours} onChange={this.changeSubmode} active_tab={this.state.submode}/>
  }

  renderMode = ({item, index}) => {
    var active = item.id === this.state.submode
    var backgroundColor = active ? GLOBAL.style.secondaryColor : 'transparent'
    // return(
    //   <Pressable key={index} onPress={()=>this.changeSubmode(item.id)} style={[{padding:GLOBAL.padding/2, borderRadius:GLOBAL.padding / 4, borderWidth:1, borderColor:GLOBAL.style.borderColor, marginRight:GLOBAL.padding/2, backgroundColor}, MainStyles.flexCenterStart]}>
    //     <Text style={{color:GLOBAL.style.color, fontFamily:GLOBAL.fonts.bold, fontSize:12}}>
    //     {item?.title}
    //     </Text>
    //   </Pressable>
    // )

    // return(
    //   <Pressable key={index}
    //   onPress={()=>this.changeSubmode(item.id)}
    //   style={[{padding:GLOBAL.padding/2, paddingVertical:GLOBAL.padding / 4, marginRight:GLOBAL.padding/2}, MainStyles.flexCenterStart]}>
    //     <Text style={{color:GLOBAL.style.color, fontFamily:GLOBAL.fonts.black, fontSize:16, textTransform:'none', opacity:isActive ? 1 : 0.33}}>
    //     {item?.title}
    //     </Text>
    //   </Pressable>
    // )
    return(
      <Pressable onPress={()=>this.changeSubmode(item.id)} style={[{paddingHorizontal:GLOBAL.padding*1, paddingVertical:GLOBAL.padding / 2}, MainStyles.flexCenter]}>
        <Text style={{fontFamily:GLOBAL.fonts.black, fontSize:16, color:GLOBAL.style.color, opacity:active ? 1 : 0.5, textTransform:'uppercase'}}>
        {item.title}
        </Text>
      </Pressable>
    )
  }

  render = () => {
    var { loading, refreshing } = this.state
    try{
      return (
        <View style={[{flex:1, width:'100%'}, MainStyles.flexStartCenter]}>
          <View style={[{width:'100%', borderBottomWidth:1, borderBottomColor:GLOBAL.style.content?.borderColor, borderRadius:0, overflow:'hidden'}, MainStyles.flexCenter]}>
          {this.renderModes()}
          <View style={{width:'100%', height:1, backgroundColor:GLOBAL.style.content?.borderColor}}/>
          {this.renderTabs()}
          </View>
          {
            loading || refreshing ? <Loading string='Loading tournaments'/> :
            this.renderContent()
          }
        </View>
      );
    }catch(e){
      return null
    }
  }

}
