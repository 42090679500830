/* @flow */

import React, { PureComponent } from "react";
import { View, Text, Image, RefreshControl, TextInput } from "react-native";
import { Pressable, Hoverable } from "react-native-web-hover";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { getApp } from "firebase/app";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

//

//Components
import MatchList from "../../components/matches/matchlist.js";
import Tabs from "../../components/general/tabs.js";

//Helpers
import useList from "../../helpers/useList.js";
import MainStyles from "../../helpers/mainstyles.js";
import Functions from "../../helpers/functions.js";
import GLOBAL from "../../helpers/global.js";
import TextInputDebounced from '../../components/general/TextInputDebounced.js'

// // Initialize Firebase
const app = getApp();
const db = getFirestore(app);

//Modules
var moment = require("moment-timezone");
var odds_conversion = require("oddslib");

const tz = moment.tz.guess(true);
const fallbackOpacity = 0.65;
const fallbackOpacityScore = fallbackOpacity - 0.2;
var DATE_WIDTH = 100;

const red = "#E42958";
const orange = "#F28C28";
const yellow = "#E8C300";

const tours = [
  { id: "all", title: "All tours" },
  { id: "tour", title: "ATP & WTA" },
  { id: "atp", title: "ATP" },
  { id: "wta", title: "WTA" },
  { id: "challenger", title: "Challenger" },
  { id: "itf", title: "ITF" },
  { id: "itf_men", title: "ITF Men" },
  { id: "itf_women", title: "ITF Women" },
  { id: "utr", title: "UTR" },
];

const purple = "rgba(128, 94, 255, 1)";
const purpleLow = "rgba(128, 94, 255, 0.2)";

export default class MyComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dates: [],
      mode: "all",
      tour: "all",
      date_index: 4,
      countries: this.props.countries || [],
      missedUpdates: 0,
      lastUpdate: 0,
      visible: true,
      push: {},
      useList: useList(),
      searchValue:'',
      // default_tour:this.props.default_tour,
    };

    this.unsubscribeFirestore = null;
  }

  componentDidMount = async () => {
    this.setupFirestore();

    // alert(this.state.useList)

    const oddsEnabled = localStorage.getItem("oddsEnabled") === "true";
    const oddsFormat = localStorage.getItem("oddsFormat");
    const lastTour = localStorage.getItem("lastTour");
    const lastMode = localStorage.getItem("lastMode");
    const lastModeUpdated = localStorage.getItem("lastModeUpdated");

    await this.setState({
      oddsSettings: { enabled: oddsEnabled, format: oddsFormat },
      tour: lastTour || "all",
    });

    //Apply the last mode if it was recent
    if (
      lastMode &&
      lastModeUpdated >= Number(moment().subtract(30, "minutes").format("x"))
    ) {
      await this.setState({ mode: lastMode });
    }

    this.full_setup();

    window.addEventListener("focus", this.handleFocus);
    window.addEventListener("blur", this.handleBlur);
    window.addEventListener("localStorageChange", this.handleStorageChange);
    document.addEventListener("visibilitychange", this.handleVisibilityChange);

    if (this.props.navigation) {
      this._unsubscribe = this.props.navigation.addListener("blur", () => {
        // console.log('Scores is unfocused');
        this.setState({ unmounted: true });
      });
      this._unsubscribe2 = this.props.navigation.addListener("focus", () => {
        // console.log('Scores is focused');
        this.setState({ unmounted: false });
      });
    }
  };

  setupFirestore = () => {
    // console.log('Attaching FS')
    // Attach the listener
    this.unsubscribeFirestore = onSnapshot(
      doc(db, "summaries_sr", "push"),
      (doc) => {
        this.updateFromFirestore(doc);
      }
    );
  };

  updateFromFirestore = async (doc) => {
    // console.log("Current data: ", doc.data().matches);
    const matches = (doc.data && doc.data() && doc.data().matches) || {};
    await this.setState({ push: Object.assign(this.state.push, matches) });

    //Update the list
    this.filter_matches();
  };

  detachFirestore = () => {
    if (this.unsubscribeFirestore) {
      // console.log('Detaching FS')
      this.unsubscribeFirestore();
    }
  };

  handleStorageChange = async () => {
    const oddsEnabled = localStorage.getItem("oddsEnabled") === "true";
    const oddsFormat = localStorage.getItem("oddsFormat");
    await this.setState({
      oddsSettings: { enabled: oddsEnabled, format: oddsFormat },
    });
    this.filter_matches();
  };

  componentWillUnmount = async () => {
    clearTimeout(this.refresh_data);
    this.refresh_data = null;
    this.setState({ unmounted: true });
    window.removeEventListener("focus", this.handleFocus);
    window.removeEventListener("blur", this.handleBlur);
    window.removeEventListener("localStorageChange", this.handleStorageChange);
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
    if (this._unsubscribe) {
      this._unsubscribe();
    }
    if (this._unsubscribe2) {
      this._unsubscribe2();
    }
    this.detachFirestore();
  };

  //
  handleFocus = () => {
    // console.log('focus')
    this.setState({ unmounted: false });
    // this.handleVisibilityChange('visible')
    // this.setState({visible:true})
  };

  handleBlur = () => {
    // console.log('hidden')
    // this.handleVisibilityChange('hidden')
    // this.setState({unmounted:false})
  };

  handleVisibilityChange = (currentState) => {
    // console.log('Vis change', document.visibilityState)

    // console.log('Visibility changed to: '+document.visibilityState, "Seconds to compare: "+time_compare/1000+" seconds")

    let isVisible =
      document.visibilityState === "visible" && currentState !== "hidden";

    // console.log('isVisible', !!isVisible, 'updateRequired', !!this.state.updateRequired)

    if (isVisible && this.state.updateRequired) {
      this.setup(null, null, null, true);
    }

    if (document.visibilityState === "visible") {
      this.setupFirestore();
    } else {
      this.detachFirestore();
    }

    // if(document.visibilityState === 'visible'){
    //   //Trigger an update if the data was updated more than a minute ago
    //   if((Date.now() - this.state.lastUpdate) > time_compare){
    //     // console.log('Viewing page and data is stale, asking for refresh')
    //     this.setup(null, null, null, true)
    //   }else{
    //     // console.log('Viewing page but data is fresh, wait for next update')
    //   }
    // }
  };

  reset = () => {
    clearTimeout(this.refresh_data);
    this.setState({ mode: "all" });
    this.setup(null, true, true);
  };

  refresh = () => {
    clearTimeout(this.refresh_data);
    this.setState({ refreshing_all: true });
    this.full_setup();
  };

  full_setup = async () => {
    clearTimeout(this.refresh_data);
    await this.setupDates();
    this.setup();
  };

  //
  viewDatePicker = () => {
    this.setState({ show_picker: true });
  };

  //
  switchDate = async (increase) => {
    var { date_index, dates } = this.state;
    if (increase) {
      date_index = date_index + 1;
    } else {
      date_index = date_index - 1;
    }

    var date = dates[date_index];
    await this.setState({ date_index });
    this.changeDate(date);
  };

  changeDate = async (date) => {
    clearTimeout(this.refresh_data);
    var { mode } = this.state;
    if (mode !== "all") {
      mode = "all";
    }
    this.setState({ date, mode });
    this.setup({ date: date.value }, false, true);
  };

  setupDates = async () => {
    var tz = await moment.tz.guess(true);

    var dates = [-4, -3, -2, -1, 0, 1, 2, 3, 4];
    var date_index = 4;

    await Promise.all(
      dates.map(async (date, index) => {
        dates[index] = {};
        if (date > 0) {
          dates[index] = {
            header: moment().tz(tz).add(date, "days").format("dddd"),
            title: moment().tz(tz).add(date, "days").format("D"),
            subtitle: moment().tz(tz).add(date, "days").format("MMM"),
            value: moment().tz(tz).add(date, "days").format("YYYY-MM-DD"),
            selector_string: moment()
              .tz(tz)
              .add(date, "days")
              .format("ddd DD MMM"),
          };
        } else if (date < 0) {
          dates[index] = {
            header: moment()
              .tz(tz)
              .subtract(date * -1, "days")
              .format("dddd"),
            title: moment()
              .tz(tz)
              .subtract(date * -1, "days")
              .format("D"),
            subtitle: moment()
              .tz(tz)
              .subtract(date * -1, "days")
              .format("MMM"),
            value: moment()
              .tz(tz)
              .subtract(date * -1, "days")
              .format("YYYY-MM-DD"),
            selector_string: moment()
              .tz(tz)
              .add(date, "days")
              .format("ddd DD MMM"),
          };
        } else if (date === 0) {
          dates[index] = {
            header: "Today",
            title: moment().tz(tz).format("D"),
            subtitle: moment().tz(tz).format("MMM"),
            today: true,
            value: moment().tz(tz).format("YYYY-MM-DD"),
            selector_string: "Today",
          };
          // date_index = index
        }

        if (date === -1) {
          dates[index].header = "Yesterday";
          dates[index].selector_string = "Yesterday";
        }
        if (date === 1) {
          dates[index].header = "Tomorrow";
          dates[index].selector_string = "Tomorrow";
        }
      })
    );

    var today = moment().tz(tz).format("YYYY-MM-DD");
    // var date = dates[date_index]

    var date = dates[date_index];

    await this.setState({ date, date_index, dates, today });
  };

  //
  changeTour = async (mode_data) => {
    // clearTimeout(this.refresh_data);
    await this.setState({ tour: mode_data.id });
    this.filter_matches();

    //Save the tour to localstorage
    localStorage.setItem("lastTour", mode_data.id);
    localStorage.setItem("lastTourUpdated", Date.now());
  };

  //
  changeFilter = () => {
    // clearTimeout(this.refresh_data);
    var date_string = moment(this.state.date, "YYYY-MM-DD").format(
      "D MMM YYYY"
    );
    this.props.navigation.navigate("DailyTournaments", {
      onFinish: this.applyFilter,
      date: this.state.date,
      date_string,
    });
  };

  applyFilter = (filter) => {
    this.setState({ filter });
    if (filter?.title) {
      this.setState({
        header_filter: {
          title: filter.title,
          subtitle: this.state.header_filter?.subtitle || "Matches",
        },
      });
    }
    this.setup({ filter }, false, true);
  };

  //
  changeMode = async (mode_data) => {
    // clearTimeout(this.refresh_data);

    var mode = mode_data.id;

    // if (mode === "live") {
    //   params.only_live = true;
    // } else if (mode === "following") {
    //   params.only_favorites = true;
    // } else if (mode === "completed") {
    //   params.only_completed = true;
    // } else if (mode === "upcoming") {
    //   params.only_upcoming = true;
    // }

    await this.setState({ mode });
    this.filter_matches();
    localStorage.setItem("lastMode", mode);
    localStorage.setItem("lastModeUpdated", Date.now());
    // this.setup(params, false, true);
  };

  setup = async (params, reset, showRefreshing, retry) => {
    clearTimeout(this.refresh_data);
    this.setState({ lastUpdate: Date.now() });

    var { dates, mode } = this.state;

    if (showRefreshing) {
      await this.setState({ sections: [], refreshing: true });
    }

    if (!params) {
      params = {};
    }

    params.web = true;
    params.url = "https://gen2-matches-daily-web-ysvbugl7mq-uc.a.run.app";
    // params.url =
    //   "http://127.0.0.1:5001/tennis-lwts/us-central1/gen2_matches_daily_web";

    if (!reset) {
      if (!params.date) {
        params.date = this.state.date ? this.state.date.value : null;
      }
    }

    var data_r = await Functions.tnnsAPI(params);
    if (!data_r) data_r = {};
    const { all_matches, refresh_time, sids } = data_r;

    await this.setState({ all_matches, refresh_time, sids });
    this.filter_matches();

    // var data = data_r?.data || {}
    // var { sections, header_date, header_filter, date, refresh_time } = data
    this.refresh_time(refresh_time);

    // await this.setState({sections, header_date, header_filter, loading:false, refreshing:false, refreshing_all:false})
  };

  filter_matches = async () => {
    let { all_matches, tour, mode, sids } = this.state;

    //Filter the matches based on the mode
    let matches = [...(all_matches || [])];

    //Filter the mode based on the tour
    if (tour && tour !== "all") {
      matches = matches.filter(
        (obj) => sids[obj.sid].d.tour && sids[obj.sid].d.tour.includes(tour)
      );
    }

    const live_count = matches.filter((obj) => obj?.fs?.includes("l")).length;

    if (mode === "live") {
      matches = matches.filter((obj) => obj?.fs?.includes("l"));
    } else if (mode === "upcoming") {
      matches = matches.filter((obj) => obj?.fs?.includes("s"));
    } else if (mode === "completed") {
      matches = matches.filter((obj) => obj?.fs?.includes("c"));
    }

    //Apply the latest firestore data
    const push = this.state.push || {};
    matches.forEach((match) => {
      // console.log(match)
      const key = `sr:sport_event:${match.k}`;
      if (push[key]) {
        const status = push[key].sport_event_status;

        if (status?.game_state) {
          if (status?.game_state) {
            //Game State
            match.gs = [
              status.game_state.home_score,
              status.game_state.away_score,
              null,
            ].map((num) => (num === 50 ? "AD" : num));

            //Serving
            if (status.game_state.serving === "home") {
              match.p[0].sv = true;
              match.p[1].sv = false;
            } else if (status.game_state.serving === "away") {
              match.p[0].sv = false;
              match.p[1].sv = true;
            }
          } else {
            match.gs = null;
            match.p[0].sv = false;
            match.p[1].sv = false;
          }

          //Period Scores
          if (status.period_scores) {
            status.period_scores.forEach((period, i) => {
              if (match.sc && match.sc[i]) {
                match.sc[i][0] = period.home_score;
                match.sc[i][1] = period.away_score;
              }
            });
          }
        }
      }
    });

    //Section the data with the sids
    let sections = Object.keys(sids || {})
      .map((sid_id) => {
        let sid = sids[sid_id];
        return {
          ...sid,
          title: sid?.t,
          subtitle: sid.su,
          data: [...matches]
            .filter((obj) => Number(obj.sid) === Number(sid_id))
            .sort((a, b) => (a.r < b.r ? -1 : 1))
            .map((obj) => this.formatMatch({ match: obj, sid })),
        };
      })
      .filter((obj) => obj.data.length);

    //Sort the sections based on the sid ranking
    await sections.sort((a, b) => (a.r < b.r ? -1 : 1));

    //Setup the header
    await this.setState({
      sections,
      live_count,
      loading: false,
      refreshing: false,
      refreshing_all: false,
    });

    this.updateSearchFilter(this.state.searchValue)
  };

  formatMatch = ({
    match,
    placement,
    droppingOdds,
    spoilerMode,
    hasAdjustedTimeMode,
    adjustedTimeMode,
    defaultTimeMode,
  }) => {
    let obj = {
      k: match.k,
    };
    try {
      // match = demoS
      // bettingMode = true

      //Mode
      let filters = match.fs || [];
      let mode;
      switch (true) {
        case filters.includes("s"):
          mode = "scheduled";
          break;
        case spoilerMode:
          mode = "spoilerMode";
          break;
        case filters.includes("c"):
          mode = "completed";
          break;
        case filters.includes("l"):
          mode = "live";
          break;
        default:
          mode = "unknown";
          break;
      }
      obj.type = mode;

      //ID
      obj.id = `sr:sport_event:${
        match.k.includes("-") ? match.k.split("-")[0] : match.k
      }`;

      // if(match.k === 'custom:guadalajara:MS201') console.log(match)

      //Players
      obj.players = match.p.map((player) => this.formatPlayer(player, mode));

      //Best of
      obj.best_of = (match.bo && Number(match.bo)) || 3;

      //Sets
      if (mode !== "scheduled" && match.d_st?.t !== "Warm up") {
        obj.sets = Array(obj.best_of)
          .fill(null)
          .map((set, i) => {
            return this.formatSet(match.sc && match.sc[i], i, mode);
          });
      }

      //Game Score
      if (mode === "live" && !match.d_st) {
        obj.game_score = this.formatSet(match.gs, 0, mode, true);
      }

      //Strings
      if (match.d_st) {
        obj.strings = {
          title: match.d_st.t || "TBC",
          subtitle: match.d_st.s,
        };
        // console.log(match)
        if (match.start_time_timestamp && tz && !match?.d_st?.t) {
          const use24h =
            defaultTimeMode || (hasAdjustedTimeMode && adjustedTimeMode);
          const timeFormat = use24h ? "H:mm" : "h:mmA";
          obj.strings.title = moment(match.start_time_timestamp, "x")
            .tz(tz)
            .format(timeFormat);
        }
      }

      //Buttons
      if (match.c?.h) {
        //Highlights available
        obj.button = {
          type: "highlights",
          data: match.c.h,
        };
      } else if (placement === "completed") {
        //Time ago
        obj.button = {
          type: "time-ago",
          text: moment(match.finishedAt, "x").fromNow(),
        };
      } else if (droppingOdds) {
        //Dropping odds
        obj.button = {
          type: "dropping-odds",
          value: this.formatDroppingOdds(match.od, mode),
        };
      } else {
        //Favorite button
        obj.button = {
          type: "favorite",
          enabled: match.isFollowing,
        };
      }

      //Odds
      if (this.state.oddsSettings.enabled) {
        obj.odds = this.formatOdds(match.od, mode);
      }
    } catch (e) {
      console.error(e);
    }

    let transformed = this.transformData(obj);

    //Create the URL slug
    //match/tournament/year/round/live-p1-p2/id/live
    // transformed.slug = `/matchnew/demo-tournament/2024/final/live-roger-federer-vs-rafael-nadal/${match.k}/live`

    return transformed;
  };

  transformData = (data) => {
    const transformedData = {};

    // Map 'k' or 'id' to 'id' (keeping 'k' since it's already short)
    transformedData.k = data.k || data.id;
    transformedData.id = data.id;

    // Transform 'players' to 'p1' and 'p2' with shortened keys
    if (data.players && data.players.length === 2) {
      const player1 = data.players[0];
      const player2 = data.players[1];

      transformedData.p1 = {
        k: player1.k,
        n: player1.name,
        s: player1.seed || null,
        f: player1.flag,
        r: player1.winner ? "w" : player1.loser ? "l" : null,
        sv: player1.serving,
        style: player1.style,
        textStyle: player1.textStyle,
      };

      transformedData.p2 = {
        k: player2.k,
        n: player2.name,
        s: player2.seed || null,
        f: player2.flag,
        r: player2.winner ? "w" : player2.loser ? "l" : null,
        sv: player2.serving,
        style: player2.style,
        textStyle: player2.textStyle,
      };
    }

    // Map 'best_of' to 'b'
    if (data.best_of !== undefined) {
      transformedData.b = data.best_of;
    }

    // Map 'sets' to 'st' and flatten the structure
    if (data.sets) {
      transformedData.s = data.sets.map((set) => ({
        n: set.number,
        h: set.home ? set.home.score : null,
        a: set.away ? set.away.score : null,
        htb: set.home ? set.home.tiebreak : null,
        atb: set.away ? set.away.tiebreak : null,
        hs: set.home ? set.home.style : null,
        as: set.away ? set.away.style : null,
      }));
    }

    //Strings
    if (data.strings) {
      transformedData.st = {
        t: data.strings.title,
        s: data.strings.subtitle,
      };
    }

    if (data.game_score) {
      transformedData.gs = {
        h: data.game_score.home.score,
        a: data.game_score.away.score,
      };
    }

    // Map 'button.enabled' to 'btnE'
    if (data.button && data.button.enabled !== undefined) {
      transformedData.btnE = data.button.enabled;
    }

    if (data.button && data.button.type === "highlights") {
      transformedData.highlights = data.button.data;
    }

    // Map 'odds' to 'od' and flatten the structure
    if (data.odds) {
      transformedData.od = {
        h: data.odds.home ? data.odds.home.value : null,
        a: data.odds.away ? data.odds.away.value : null,
        hc: data.odds.home?.color,
        ac: data.odds.away?.color,
        // c: data.odds.
      };
    }

    transformedData.type = data.type;

    return transformedData;
  };

  formatDroppingOdds = (data) => {
    const droppingOddsValue = data?.dr || {};
    const droppingColor =
      droppingOddsValue?.d > 20
        ? red
        : droppingOddsValue?.d > 10
        ? orange
        : yellow;

    let droppingIndex = -1;

    if (droppingOddsValue?.i === "0" && droppingColor) {
      droppingIndex = 0;
    }
    if (droppingOddsValue?.i === "1" && droppingColor) {
      droppingIndex = 0;
    }

    return {
      value: droppingOddsValue?.d,
      color: droppingColor,
      index: droppingIndex,
    };
  };

  formatOdds = (odds) => {
    if (!odds || !odds?.o || !odds?.o[0]) {
      return {
        home: {
          value: "-",
        },
        away: {
          value: "-",
        },
      };
    }

    // const odds_formatted = odds.o.map(odd => odd)

    let odds_formatted = odds.o;
    let implied_probabilities = null;
    try {
      if (Number(odds_formatted[0]) === 1) {
        implied_probabilities = [1, 0.01];
      } else if (Number(odds_formatted[1]) === 1) {
        implied_probabilities = [0.01, 1];
      } else {
        implied_probabilities = odds_formatted.map((odd) =>
          odds_conversion.from("decimal", Number(odd)).to("impliedProbability")
        );
      }
    } catch (e) {
      console.log(odds_formatted, e);
    }

    if (this.state.oddsSettings.format === "probability") {
      //Calc the implied probabilities and then rebase to be 100% total
      // console.log(odds_formatted)
      odds_formatted = implied_probabilities.map(
        (prob) =>
          `${Math.round(
            (prob / (implied_probabilities[0] + implied_probabilities[1])) * 100
          )}%`
      );
    } else if (this.state.oddsSettings.format === "implied_probability") {
      //
      odds_formatted = implied_probabilities.map(
        (prob) => `${Math.round(prob * 100)}%`
      );
    } else if (this.state.oddsSettings.format !== "decimal") {
      odds_formatted = odds.o.map((odd, i) => {
        const conversion = odds_conversion.from("decimal", Number(odd));
        if (this.state.oddsSettings.format === "fractional") {
          return conversion.to("fractional").toString();
        }
        if (this.state.oddsSettings.format === "american") {
          return Math.round(conversion.to("moneyline"));
        }
        try {
          if (this.state.oddsSettings.format === "implied_probability") {
            return `${Math.round(implied_probabilities[i] * 100)}%`;
          }
        } catch (e) {
          console.log(odds_formatted, e);
        }
        return odd.toFixed(2);
      });
    }

    // odds.o = odds.o.map((odd) => odd.toFixed(2))

    let obj = {
      home: {
        value: odds_formatted[0],
      },
      away: {
        value: odds_formatted[1],
      },
    };

    if (odds.c) {
      odds.c.forEach((color, i) => {
        let oddRef = i === 0 ? "home" : "away";
        if (color === "g") {
          obj[oddRef].color = "green";
        } else if (color === "r") {
          obj[oddRef].color = "#EC1181" || "#E73553";
        }
      });
    }

    return obj;
  };

  formatPlayer = (player) => {
    let type = player?.f?.length > 1 ? "doubles" : "singles";
    let obj = {
      type,
      name: player.n,
      k: player.k,
    };

    //Seed
    if (player.s) {
      obj.seed = player.s;
    }

    //Flags
    // if (type === "singles") {
    //   obj.flag = (player.f && player.f[0]) || null;
    // } else if (type === "doubles") {
    //   obj.flag1 = player.f[0];
    //   obj.flag2 = player.f[1];
    // }
    obj.flag = player.f || [];

    //Serving
    if (player.sv) {
      obj.serving = true;
    }

    //Winner/Loser
    if (player.w) {
      obj.winner = true;
      obj.textStyle = {
        fontFamily: !GLOBAL.isMobile ? "Inter-Medium" : "Inter-SemiBold",
      };
    } else {
      obj.textStyle = {
        fontFamily: !GLOBAL.isMobile ? "Inter-Regular" : "Inter-Medium",
      };
    }
    if (player.l) {
      obj.loser = true;
      obj.style = { opacity: fallbackOpacity };
    }

    //Badge
    if (player.b) {
      obj.badge = player.b;
    }

    return obj;
  };

  formatSet = (set, i) => {
    const defaultScore = {
      score: "·",
      style: { opacity: 0.2 },
    };

    // if (!set || (gameScore && !set[0] && !set[1])) {
    //   return {
    //     number: i + 1,
    //     home: defaultScore,
    //     away: defaultScore,
    //   };
    // }

    if (!set) {
      return {
        number: i + 1,
        home: defaultScore,
        away: defaultScore,
      };
    }

    let obj = {
      number: i + 1,
      home:
        set[0] || set[0] === 0 || set[0] === "AD"
          ? {
              score: set[0],
            }
          : defaultScore,
      away:
        set[1] || set[1] === 0 || set[1] === "AD"
          ? {
              score: set[1],
            }
          : defaultScore,
    };

    //Tiebreaks
    if (set[3] || set[3] === 0) {
      obj.home.tiebreak = set[3].toString();
    }
    if (set[4] || set[4] === 0) {
      obj.away.tiebreak = set[4].toString();
    }

    //Winner or loser
    const loserStyle = {
      opacity: fallbackOpacityScore,
      fontFamily: "Inter-Medium",
    };
    if (set[2] === 0) {
      obj.home.style = loserStyle;
    } else if (set[2] === 1) {
      obj.away.style = loserStyle;
    }

    return obj;
  };

  //AppState & Reload Controls
  refresh_time = (refresh_time) => {
    // console.log('Refrehstime', refresh_time)
    this.setState({ refresh_time });
    // console.log('Will refresh in '+refresh_time/1000+' seconds')
    if (refresh_time) {
      this.refresh_data = setTimeout(() => {
        this.handle_refresh(refresh_time);
      }, refresh_time);
    }
  };

  handle_refresh = async (refresh_time) => {
    var update_data = true;

    // console.log('Refresh 1', 'Unmounted?', this.state.unmounted)

    if (document.visibilityState === "visible") {
      //Update, window is visible
      update_data = true;
    } else if (this.state.missedUpdates > (1000 * 5 * 60) / refresh_time) {
      //Data is 5 mins old, update
      // console.log('Missed enough updates, update the data')
      update_data = true;
    } else {
      //Window blurred, don't update
      update_data = false;
    }

    if (this.state.unmounted) {
      // console.log('Unmounted, no refresh')
      update_data = false;
    }

    // console.log('Refresh requested', 'Visbility: '+document.visibilityState, 'Missed updates: '+this.state.missedUpdates, "Updating: "+update_data)

    if (update_data) {
      //Update
      this.setup(null, null, null, true);
      this.setState({ missedUpdates: 0, updateRequired: false });
    } else {
      //Missed
      //Increase the missed update count and wait for another refresh
      this.setState({
        missedUpdates: this.state.missedUpdates + 1,
        updateRequired: true,
      });
      // this.refresh_time(refresh_time)
    }
  };

  onAppStateChange = async (appState) => {
    var app_backgrounded = appState === "background";
    this.setState({ appState, app_backgrounded });
    if (appState === "active") {
      //Check if date today is the same
      var tz = await moment.tz.guess(true);
      var today = moment().tz(tz).format("YYYY-MM-DD");
      var today_changed = today !== this.state.today;

      if (today_changed) {
        //Today has changed, refresh the dates
        await this.setState({
          loading: true,
          refreshing: true,
          freshing_all: true,
        });
        this.full_setup();
      } else {
        //Today still the same, restart the listener
        this.setup();
      }
    }
  };

  //Controls
  renderControls = () => {
    return (
      <View
        style={[
          {
            width: "100%",
            borderWidth: 0,
            borderColor: GLOBAL.style.borderColor,
            borderRadius: GLOBAL.isMobile ? 0 : GLOBAL.borderRadius,
            marginBottom: 0,
            overflow: "hidden",
            backgroundColor: GLOBAL.style.cardColor,
          },
          MainStyles.flexCenter,
        ]}
      >
        {this.renderControls_tour()}
        {
          // !GLOBAL.isMobile &&
          // <View
          // style={{
          //   width: "100%",
          //   height: 1,
          //   backgroundColor: GLOBAL.style.content?.borderColor,
          // }}
          // />
        }
        {this.renderControls_mode()}
      </View>
    );
  };

  renderControls_mode = () => {
    var isToday = this.state?.date?.value !== this.state.today;
    var modes = [
      // {id:'following', title:'Following'},
      { id: "all", title: "All" },
      {
        id: "live",
        title: "Live",
        hide: isToday,
        count: this.state.live_count,
      },
      { id: "completed", title: "Completed", hide: isToday },
      { id: "upcoming", title: "Upcoming", hide: isToday },
    ];
    return !isToday ? (
      <View style={{ width: "100%", marginTop: GLOBAL.isMobile ? -8 : -8 }}>
        <Tabs
          tabs={modes}
          onChange={this.changeMode}
          active_tab={this.state.mode}
          secondary
        />
      </View>
    ) : null;
  };

  onChangeTour = (event) => {
    const tour = tours.find((obj) => obj.title === event.target.textContent);
    if (tour) {
      this.changeTour(tour);
    }
  };

  updateSearch = (searchValue) => {
    this.setState({searchValue})

    //Debounce filter the list
  }

  updateSearchFilter = (searchValue) => {
    // alert(`Would search: ${searchValue}`)
    // console.log('Searching', searchValue)
    if(!searchValue){
      this.setState({filtered:null})
    }else{
      const search = searchValue.toLowerCase().trim()
      const { sections } = this.state
      if(sections.length){
        // console.log(sections)
        const clonedSections = JSON.parse(JSON.stringify(sections))
        //Go through each section and flag to save if the tournament or the matches within match the saerch string
        const filtered = clonedSections.map(tournament => {
          //Tournament search terms
          const title = tournament.t && tournament.t.toLowerCase().includes(search)
          const subtitle = tournament.su && tournament.su.toLowerCase().includes(search)

          if(title || subtitle){
            //Full tournament is a match
            tournament.includeInFilter = true
            return tournament
          }else{
            //Filter individual matches
            tournament.data = tournament.data.filter(match => {
              const p1 = match.p1.n.toLowerCase().includes(search)
              const p2 = match.p2.n.toLowerCase().includes(search)

              return (p1 || p2)
            })
          }
          return tournament
        }).filter(tournament => !!tournament.data.length)
        // console.log('Filtered', filtered)
        this.setState({filtered})
      }
    }
  }

  clearInput = () => {
    this.setState({searchValue:'', filtered:null})
    this.updateSearchFilter('')
  }

  renderControls_tour = () => {
    return (
      <View
        style={{
          width: "100%",
          // flex:1,
          // backgroundColor: "blue",
          paddingHorizontal: 12,
          paddingVertical: 12,
          flexDirection: "row",
        }}
      >
        <View style={{ flex: 1 }}>
          <Select
            sx={{
              backgroundColor: GLOBAL.style.dropdown,
              fontSize: 14,
              fontFamily: "Inter-SemiBold",
              borderWidth: 0,
              boxShadow: "none",
              height: 40,
              // flex:1,
              // color: "red",

              // "&:hover": {
              //   backgroundColor: "orange",
              // },
              // "&:focus": {
              //   borderColor: "primary.outlinedBorder",
              //   outline: "2px solid",
              //   outlineColor: "primary.outlinedBorder",
              // },
              // "&:active": {
              //   backgroundColor: "pink",
              // },
            }}
            onChange={this.onChangeTour}
            value={this.state.tour}
          >
            {tours.map((item) => (
              <Option
                key={item.id}
                value={item.id}
                sx={{
                  fontSize: 14,
                  fontFamily: "Inter-Medium",
                  // backgroundColor: "grey",

                  // "&:hover": {
                  //   backgroundColor: "teal", // light green on hover
                  // },
                  // "&:focus": {
                  //   backgroundColor: "blue",
                  // },
                  // '&[aria-selected="true"]': {
                  //   backgroundColor: "yellow",
                  //   fontWeight: "bold",
                  // },
                }}
              >
                {item.title}
              </Option>
            ))}
          </Select>
        </View>
        <View
          style={{
            marginLeft: 12,
            borderRadius: 6,
            // paddingRight: 24,
            flex: 1,
            height: 40,
            backgroundColor: GLOBAL.style.dropdown,
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <View style={{flex:1, height:'100%'}}>
          <TextInputDebounced
            style={{
              fontSize: 13,
              flex: 1,
              height:'100%',
              fontFamily: GLOBAL.fonts.regular,
              color: GLOBAL.style.color,
              paddingHorizontal:12,
              // opacity: 0.3,
            }}
            placeholderTextColor={GLOBAL.style.colorSecondary}
            placeholder={GLOBAL.isMobile ? "Search" : "Search matches"}
            value={this.state.searchValue}
            onChangeText={this.updateSearch}
            onFinishEditing={this.updateSearchFilter}
          />
          </View>
          <Pressable onPress={this.clearInput}>
          <Image
            style={{
              height: this.state.searchValue ? 16 : 20,
              aspectRatio: 1,
              marginRight: 8,
              resizeMode: "contain",
              tintColor: GLOBAL.style.color,
              opacity: this.state.searchValue ? 1 : 0.35,
            }}
            source={this.state.searchValue ? require("../../assets/icons/close.png") : require("../../assets/icons/search-filled.png")}
          />
          </Pressable>
        </View>
      </View>
    );
  };

  getItemLayout = (data, index) => ({
    length: DATE_WIDTH + GLOBAL.padding / 2,
    offset: (DATE_WIDTH + GLOBAL.padding / 2) * index,
    index,
  });

  renderControls_dates = () => {
    var { dates, date_index, date } = this.state;
    var size = GLOBAL.isMobile ? 32 : 28;

    var back = dates[date_index - 1];
    var forward = dates[date_index + 1];

    var disabled_opacity = 0.15;

    const buttonPadding = 14;
    const backgroundColor =
      (!GLOBAL.style.active_tab && "transparent") ||
      this.props.accent ||
      purple;
    const color = (!GLOBAL.style.active_tab && purple) || "white";

    const bg = (
      <View
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          backgroundColor: purpleLow,
        }}
      />
    );

    return (
      <View
        style={[
          {
            width: "100%",
            borderBottomColor: GLOBAL.style.borderColor,
            borderBottomWidth: 1,
            paddingVertical: GLOBAL.isMobile ? 0 : 4,
          },
          MainStyles.flexRow,
          MainStyles.flexBetweenCenter,
        ]}
      >
        <Hoverable style={{}}>
          {() => (
            <Pressable
              style={{ padding: buttonPadding }}
              onPress={!back ? null : () => this.switchDate(false)}
            >
              <View
                style={[
                  {
                    height: size,
                    width: size,
                    borderRadius: 100,
                    // backgroundColor: hovered
                    //   ? GLOBAL.style.hoverTab
                    //   : "rgba(0,0,0,0.04)",
                    backgroundColor,
                    opacity: back ? 1 : disabled_opacity,
                    overflow: "hidden",
                  },
                  MainStyles.flexCenter,
                ]}
              >
                {bg}
                <Image
                  style={{
                    transform: [{ rotate: "0deg" }],
                    marginRight: 2,
                    height: size / 2,
                    width: size / 2,
                    resizeMode: "contain",
                    tintColor: color || GLOBAL.style.color,
                  }}
                  source={require("../../assets/icons/arrow.png")}
                />
              </View>
            </Pressable>
          )}
        </Hoverable>

        <View
          // onPress={()=>this.setState({show_picker:!this.state.show_picker})}
          style={[{ flex: 1 }, MainStyles.flexCenter]}
        >
          <Text
            style={{
              fontSize: 15,
              color: GLOBAL.style.color,
              fontFamily: GLOBAL.fonts.bold,
            }}
          >
            {date?.header}
          </Text>
          {!date?.subtitle ? null : (
            <Text
              style={{
                marginTop: 2,
                fontSize: 12,
                color: GLOBAL.style.color_secondary,
                fontFamily: GLOBAL.fonts.regular,
              }}
            >
              {date?.title + " " + date?.subtitle}
            </Text>
          )}
        </View>

        <Hoverable style={{}}>
          {() => (
            <Pressable
              style={{ padding: buttonPadding }}
              onPress={!forward ? null : () => this.switchDate(true)}
            >
              <View
                style={[
                  {
                    height: size,
                    width: size,
                    borderRadius: 100,
                    // backgroundColor: hovered
                    //   ? GLOBAL.style.hoverTab
                    //   : "rgba(0,0,0,0.04)",
                    backgroundColor,
                    opacity: forward ? 1 : disabled_opacity,
                    overflow: "hidden",
                  },
                  MainStyles.flexCenter,
                ]}
              >
                {bg}
                <Image
                  style={{
                    transform: [{ rotate: "180deg" }],
                    marginLeft: 2,
                    height: size / 2,
                    width: size / 2,
                    resizeMode: "contain",
                    tintColor: color || GLOBAL.style.color,
                  }}
                  source={require("../../assets/icons/arrow.png")}
                />
              </View>
            </Pressable>
          )}
        </Hoverable>
        {
          // this.renderDatePicker()
        }
      </View>
    );
  };

  renderDatePicker = (override) => {
    var { show_picker, dates } = this.state;
    if (!override && !show_picker) {
      return null;
    } else {
      return (
        <View
          style={[
            { position: "absolute", width: "100%", bottom: 0 },
            MainStyles.flexCenter,
          ]}
        >
          <View
            style={{
              padding: GLOBAL.padding,
              borderRadius: GLOBAL.borderRadius,
              backgroundColor: "white",
            }}
          >
            {dates.map((item, index) => this.renderDateList(item, index))}
          </View>
        </View>
      );
    }
  };

  renderDateList = (item) => {
    return (
      <Hoverable style={{}}>
        {() => (
          <Pressable>
            <Text>{item.selector_string}</Text>
          </Pressable>
        )}
      </Hoverable>
    );
  };

  renderDate = ({ item, index }) => {
    var isActive = item.value === this.state.date;

    var backgroundColor = isActive ? GLOBAL.colors.dark : "rgba(0,0,0,0.04)";
    var color = isActive ? "white" : GLOBAL.style.color;

    return (
      <Pressable
        key={index}
        onPress={() => this.changeDate(item.value)}
        // style={[{width:DATE_WIDTH, height:48, borderRadius:GLOBAL.borderRadius, borderWidth:0, borderColor:isActive ? GLOBAL.style.accent : GLOBAL.style.borderColor, marginRight:GLOBAL.padding/2, backgroundColor}, MainStyles.flexCenter]}
        style={({ hovered }) => [
          {
            width: DATE_WIDTH,
            height: 48,
            borderRadius: GLOBAL.borderRadius,
            borderWidth: 0,
            borderColor: isActive
              ? GLOBAL.style.accent
              : GLOBAL.style.borderColor,
            marginRight: GLOBAL.padding / 2,
            backgroundColor,
          },
          MainStyles.flexCenter,
          hovered && !isActive && { backgroundColor: GLOBAL.style.hoverTab },
          // focused && styles.buttonFocused,
          // pressed && styles.buttonPressed
        ]}
      >
        <Text
          style={{
            color,
            opacity: 0.5,
            fontFamily: GLOBAL.fonts.regular,
            fontSize: 12,
          }}
        >
          {item?.header}
        </Text>
        <Text style={{ color, fontFamily: GLOBAL.fonts.medium, fontSize: 12 }}>
          {item?.title} {item?.subtitle}
        </Text>
      </Pressable>
    );
  };

  onLayout = ({ nativeEvent }) => {
    var height = nativeEvent.layout.height;
    // alert(height)
    window.parent.postMessage(
      {
        target: "tnnsWidgetHeight",
        value: height,
      },
      "*"
    );
  };

  render = () => {
    try {
      if (GLOBAL.useList) {
        return (
          <View
            style={[
              { flex: 1, width: "100%", borderRadius: 0 },
              MainStyles.flexStartCenter,
            ]}
          >
            <View
              onLayout={this.onLayout}
              style={[{ width: "100%", flex: 1 }, MainStyles.flexCenter]}
            >
              <View style={{ width: "100%", backgroundColor: "transparent" }}>
                {this.renderControls_dates()}
              </View>
              <MatchList
                theme={this.props.theme}
                onPress={this.props.onPressMatch}
                header={this.renderControls}
                data={this.state.filtered || this.state.sections}
                loading={this.state.loading}
                refreshing={this.state.refreshing}
                hideVideos={this.props.hideVideos}
                oddsFormat={this.state.oddsSettings.format}
                emptyStateButton={{
                  title: "Reset Filters",
                  onPress: this.reset,
                }}
                refreshControl={
                  <RefreshControl
                    // size={'small'}
                    tintColor={GLOBAL.style.color}
                    refreshing={this.state.refreshing_all}
                    onRefresh={this.refresh}
                  />
                }
                new
              />
            </View>
          </View>
        );
      }

      return (
        <View
          style={[
            { flex: 1, width: "100%", borderRadius: 0 },
            MainStyles.flexStartCenter,
          ]}
        >
          <View
            onLayout={this.onLayout}
            style={[{ width: "100%" }, MainStyles.flexCenter]}
          >
            <View style={{ width: "100%", backgroundColor: "transparent" }}>
              {this.renderControls_dates()}
            </View>
            <MatchList
              theme={this.props.theme}
              onPress={this.props.onPressMatch}
              header={this.renderControls}
              data={this.state.filtered || this.state.sections}
              loading={this.state.loading}
              refreshing={this.state.refreshing}
              hideVideos={this.props.hideVideos}
              oddsFormat={this.state.oddsSettings.format}
              emptyStateButton={{
                title: "Reset Filters",
                onPress: this.reset,
              }}
              refreshControl={
                <RefreshControl
                  // size={'small'}
                  tintColor={GLOBAL.style.color}
                  refreshing={this.state.refreshing_all}
                  onRefresh={this.refresh}
                />
              }
              new
            />
          </View>
        </View>
      );
    } catch (e) {
      console.error(e);
      return null;
    }
  };
}
