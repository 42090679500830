import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Picker, Image, ScrollView } from "react-native";
import Loading from "../../components/general/loading.js";
import Tabs from "../../components/general/tabs.js";

import Functions from "../../helpers/functions.js";
import GLOBAL from "../../helpers/global.js";
const purple = "rgb(128, 94, 255)";
const light_green = "rgba(180,249,224,1)";

const Odds = ({ id, listHeader }) => {
  const [state, setState] = useState({
    markets: {},
    odds: [],
    loading: true,
  });

  useEffect(() => {
    setup();
  }, []);

  const setup = async () => {
    let params = {
      mode: "match_odds",
      id,
    };
    // console.log("Getting odds");
    // params.url = 'http://127.0.0.1:5001/tennis-lwts/us-central1/gen2_web_api'
    const data = await Functions.tnnsAPI(params);
    // console.log(data);
    const { markets, odds } = data?.data || {};
    const { active_tab } = odds || {};

    let abbreviations = [];
    (markets?.data || []).forEach((market) => {
      if (market.title === "Money") {
        abbreviations = market.values.map((value) => value.header);
        //   if(!GLOBAL.isMobile){
        //     market.values.forEach((value, i) => {
        //         value.header = `${value.header} to win`;
        //     });
        //   }
      }
      if (market.title === "Total") {
        if (!GLOBAL.isMobile) {
          market.values.forEach((value, i) => {
            value.header = value.header
              .replace("O", "Over")
              .replace("U", "Under");
          });
        }
      }
    });

    setState({
      markets,
      odds,
      loading: false,
      active_tab,
      abbreviations,
    });
  };

  const renderTitle = (title, subtitle) => (
    <View
      style={{
        marginBottom: 12,
        width: "100%",
      }}
    >
      <Text
        style={{
          fontSize: 15,
          color: GLOBAL.style.color,
          fontFamily: "Inter-SemiBold",
        }}
      >
        {title}
      </Text>
      {subtitle && (
        <Text
          style={{
            fontSize: 12,
            color: GLOBAL.style.color,
            fontFamily: GLOBAL.fonts.regular,
            opacity: 0.67,
            marginTop: 6,
          }}
        >
          {subtitle}
        </Text>
      )}
    </View>
  );

  const renderContent = () => {
    if(listHeader){
      if (!state.odds && !state.markets) {
        return (
          <View style={{ width: "100%", padding: 16, justifyContent: "center" }}>
            <Text
              style={{
                fontFamily: GLOBAL.fonts.regular,
                fontSize: 13,
                color: GLOBAL.style.color,
              }}
            >
              No odds available
            </Text>
          </View>
        );
      }
      return (
        <ScrollView style={{width:'100%', flex:1}}>
          {listHeader}
          {renderMarketAverage()}
          {renderBookmakers()}
        </ScrollView>
      );
    }
    if (!state.odds && !state.markets) {
      return (
        <View style={{ width: "100%", padding: 16, justifyContent: "center" }}>
          <Text
            style={{
              fontFamily: GLOBAL.fonts.regular,
              fontSize: 13,
              color: GLOBAL.style.color,
            }}
          >
            No odds available
          </Text>
        </View>
      );
    }
    return (
      <>
        {renderMarketAverage()}
        {renderBookmakers()}
      </>
    );
  };

  const renderMarketHeader = (title, values) => {
    return null;
    return (
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "center",
          marginVertical: 6,
          opacity: 0.5,
        }}
      >
        <Text
          style={{
            width: 90,
            fontSize: 11,
            color: GLOBAL.style.color,
            fontFamily: GLOBAL.fonts.bold,
            textTransform: "uppercase",
          }}
        >
          {title}
        </Text>
        {(values || []).map((player) => (
          <View style={{ flex: 1, paddingLeft: 12 }}>
            <Text
              style={{
                fontSize: 11,
                color: GLOBAL.style.color,
                fontFamily: GLOBAL.fonts.bold,
                textTransform: "uppercase",
                flex: 1,
                textAlign: "left",
              }}
            >
              {player}
            </Text>
          </View>
        ))}
      </View>
    );
  };

  const renderMarketAverage = () => {
    if (!state?.markets) {
      return null;
    }
    const { data, title, description, players } = state?.markets || {};
    return (
      <View style={{ width: "100%", padding: 16 }}>
        {renderTitle(title, description)}
        {renderMarketHeader(" ", players.values)}
        {(data || []).map((item, index) => (
          <>
            {
              item.title === "Total" && null
              // <View style={{width:'100%', marginTop:12}}>
              //     {renderMarketHeader(" ", ['Over', 'Under'])}
              // </View>
            }
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
                marginVertical: 8,
              }}
            >
              <Text
                style={{
                  width: 72,
                  fontSize: 13,
                  color: GLOBAL.style.color,
                  fontFamily: GLOBAL.fonts.regular,
                }}
              >
                {item.title}
              </Text>
              {item.values.map((value, i) => (
                <>
                  <View style={{ flex: 1, paddingLeft: 16 }}>
                    <View
                      style={{
                        width: "100%",
                        backgroundColor:GLOBAL.style.dropdown,
                        // borderWidth:2,
                        // borderColor:item.outcome === i ? light_green : GLOBAL.style.dropdown,
                        borderRadius: 4,
                        paddingVertical: 12,
                        paddingHorizontal: 12,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 13,
                          color: GLOBAL.style.color,
                          fontFamily: GLOBAL.fonts.medium,
                        }}
                      >
                        {value.header}
                      </Text>
                      <Text
                        style={{
                          fontSize: 13,
                          opacity: 0.7,
                          marginTop: 0,
                          color: GLOBAL.style.color,
                          fontFamily: GLOBAL.fonts.regular,
                        }}
                      >
                        {value.value}
                      </Text>
                    </View>
                  </View>
                </>
              ))}
            </View>
          </>
        ))}
      </View>
    );
  };

  const changeMarket = (market) => {
    setState({ ...state, active_tab: market.id });
  };

  const renderBookmakers = () => {
    if (!state?.odds) {
      return null;
    }
    const { data, title, description, tabs } = state?.odds || {};
    if (!tabs?.length) return null;
    return (
      <View
        style={{
          marginTop: 6,
          //   borderTopWidth: 1,
          //   borderTopColor: GLOBAL.style.borderColor,
          paddingTop: 16,
          //   paddingHorizontal: 16,
          width: "100%",
        }}
      >
        <View
          style={{ width: "100%", paddingHorizontal: 16, marginBottom: -4 }}
        >
          {renderTitle(title, description)}
        </View>
        <View
          style={{
            width: "100%",
            borderTopWidth: 0,
            borderBottomWidth: 1,
            marginTop: 0,
            borderColor: GLOBAL.style.content.borderColor,
          }}
        >
          <Tabs
            tabs={tabs}
            onChange={changeMarket}
            active_tab={state.active_tab}
            secondary
          />
        </View>
        {renderMarket()}
      </View>
    );
  };

  const renderMarket = () => {
    const odds_width = 90;
    const { active_tab, odds } = state;
    const { data } = odds;
    const active_data = data[`${active_tab}`];
    if (active_data && active_data.length) {
      return (
        <View style={{ width: "100%" }}>
          {active_data.map((item, index) => (
            <View style={{ width: "100%", marginTop: 0 }}>
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingHorizontal: 16,
                  backgroundColor: GLOBAL.style.dropdown,
                  paddingVertical: 6,
                  borderBottomWidth: 1,
                  borderBottomColor: GLOBAL.style.content.borderColor,
                }}
              >
                <Text
                  style={{
                    flex: 1,
                    fontFamily: GLOBAL.fonts.medium,
                    fontSize: 12,
                    opacity: 0.5,
                    color: GLOBAL.style.color,
                    paddingLeft: 8,
                  }}
                >
                  Book
                </Text>
                {(item.headers || []).map((header) => (
                  <Text
                    style={{
                      width: odds_width,
                      textAlign: "left",
                      fontFamily: GLOBAL.fonts.bold,
                      fontSize: 11,
                      color: GLOBAL.style.color,
                      paddingHorizontal: 12,
                      textTransform: "uppercase",
                      opacity: 0.87,
                    }}
                  >
                    {header}
                  </Text>
                ))}
              </View>
              {!item.rows.length && (
                <View
                  style={{
                    paddingHorizontal: 16,
                    paddingVertical: 12,
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: GLOBAL.style.color,
                      fontFamily: GLOBAL.fonts.regular,
                      opacity: 0.67,
                      marginTop: 6,
                    }}
                  >
                    No books available for this market.
                  </Text>
                </View>
              )}
              {item.rows.map((row, ri) => (
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    paddingHorizontal: 16,
                    height: 52,
                    borderBottomWidth: 1,
                    borderBottomColor: GLOBAL.style.content.borderColor,
                  }}
                >
                  <View style={{ flex: 1, alignItems: "flex-start" }}>
                    <Image
                      style={{
                        height: 36,
                        width: 80,
                        borderRadius: 4,
                        resizeMode: "cover",
                        borderWidth: 1,
                        borderColor: GLOBAL.style.content.borderColor,
                      }}
                      source={{ uri: row.logo }}
                    />
                  </View>
                  {row.values.map((value) => (
                    <View
                      style={{
                        width: odds_width,
                        borderLeftWidth: 1,
                        paddingHorizontal: 12,
                        borderLeftColor: GLOBAL.style.content.borderColor,
                        height: "100%",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "left",
                          fontSize: 13,
                          color: GLOBAL.style.color,
                          fontFamily: GLOBAL.fonts.regular,
                        }}
                      >
                        {value.text}
                      </Text>
                      {value.icon && (
                        <Text
                          style={{
                            color: value.icon.color,
                            textDecorationLine: "line-through",
                            marginTop: 2,
                            fontSize: 11,
                            fontFamily: GLOBAL.fonts.regular,
                            marginLeft: 8,
                          }}
                        >
                          {Number(value.icon.text).toFixed(2)}
                        </Text>
                      )}
                    </View>
                  ))}
                </View>
              ))}
            </View>
          ))}
        </View>
      );
    }
  };

  return (
    <View style={styles.container}>
      {state?.loading && listHeader || null}
      {state?.loading ? <Loading /> : renderContent()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width:'100%',
  },
  text: {
    fontSize: 20,
    textAlign: "center",
    margin: 10,
  },
});

export default Odds;
