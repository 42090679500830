/* @flow */

import React, { Component } from 'react';
import {
  View,
  Text,
  Pressable,
  Image,
  TextInput,
  Dimensions,
  Animated,
  StyleSheet,
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {createFilter} from 'react-search-input'

//Components
// import Image from '../general/image.js'
import Tournament from '../tournament/tournament.js'
import Player from '../players/player.js'


//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

const dimensions = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
}

export default class MyComponent extends Component {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      darkMode:GLOBAL.darkMode,
      showBackground:this.props.showBackground,
      searchTerm:'',
      showSearch:false,
      loadingSearch:true,
      tournaments_all:[],
      players_all:[],
    }
  }

  async componentDidMount(){
    var hide = GLOBAL.isMobile
    this.setState({hide})
  }

  openScreen = (screen) => {
    GLOBAL.navigation.push(screen)
  }

  openDownload = (app) => {
    var url = ''
    if(app === 'apple'){
      // url = "https://apps.apple.com/app/tnns-tennis-live-scores/id1547205724"
      url = "https://apps.apple.com/app/tnns-tennis-live-scores/id1547205724?platform=iphone"
    }else if(app === 'android'){
      url = "https://play.google.com/store/apps/details?id=com.tennisrn"
    }

    window.open(url)
  }

  renderContent = () => {
    var sections = [
      {
        title:'Quick Links',
        options:[
          {text:'Scores', screen:'Scores'},
          // {text:'Streams', screen:'Streams'},
          {text:'Tournaments', screen:'Tournaments'},
          // {text:'Fantasy', screen:'Fantasy'},
          {text:'Rankings', screen:'Rankings'},
        ]
      },
      {
        title:'About',
        options:[
        {text:'Support', screen:'Support'},
        {text:'Feedback', screen:'Feedback'},
        {text:'Contact', url:'mailto:support@downloadtennis.com'},
        // {text:'Terms', screen:'Terms'},
        // {text:'Privacy', screen:'Privacy'},
        ]
      },
      {
        title:'Download the app',
        options:[
          {app:'apple'},
          {app:'android'},
        ]
      },
    ]

    if(GLOBAL.isMobile){
      sections = [
        {
          title:'Quick Links',
          options:[
            {text:'Scores', screen:'Scores'},
            {text:'Streams', screen:'Streams'},
            {text:'Tournaments', screen:'Tournaments'},
            // {text:'Fantasy', screen:'Fantasy'},
            {text:'Rankings', screen:'Rankings'},
          ]
        },
        {
          title:'About',
          options:[
          {text:'Support', screen:'Support'},
          {text:'Feedback', screen:'Feedback'},
          // {text:'Terms', screen:'Terms'},
          // {text:'Privacy', screen:'Privacy'},
          ]
        },
      ]
    }

    return(
      <View style={[{width:'100%', borderTopColor:GLOBAL.style.borderColor, borderTopWidth:0, marginTop:GLOBAL.isMobile ? 0 : 72, backgroundColor:GLOBAL.style.content.card, paddingVertical:GLOBAL.padding*2, paddingHorizontal:GLOBAL.isMobile ? GLOBAL.padding : 0}, MainStyles.flexCenter]}>
        <View style={[{width:'100%', maxWidth:GLOBAL.maxWidth}, MainStyles.flexStartStart, MainStyles.flexRow]}>
          {sections.map((item, index) => this.renderColumn(item, index))}
        </View>
      </View>
    )
  }

  renderColumn = ({title, options}) => {
    return(
      <View style={[{width:GLOBAL.isMobile ? 170 : 300 }, MainStyles.flexStartStart]}>
        <Text style={{fontFamily:GLOBAL.fonts.ultra, color:GLOBAL.style.color, fontSize:14, letterSpacing:1, textTransform:'uppercase', marginBottom:0}}>
        {title}
        </Text>
        {options.map((item, index) => this.renderColumnOption(item, index))}
      </View>
    )
  }

  openNavItem = (data) => {
    if(data.screen){
      this.openScreen(data.screen)
    }else if(data.url){
      window.open(data.url, '_self')
    }else if(data.app){
      this.openDownload(data.app)
    }
  }

  renderColumnOption = (item, index) => {

    var image = require('../../images/apple_app.png')
    if(item.app === 'android'){image = require('../../images/google_app.png')}

    return(
      <Pressable onPress={()=>this.openNavItem(item)} style={{marginTop:16}}>
        {
          item.text ?
          <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:13, letterSpacing:0}}>
          {item.text}
          </Text>
          :
          <Image style={{width:150, height:60, resizeMode:'contain', marginBottom:0, marginLeft:0}} source={image}/>
        }
      </Pressable>
      )
  }

  openPage = (item) => {
    if(item.page){
      GLOBAL.navigation.push(item.page)
    }else if(item.url){
      Functions.openURL(item.url)
    }
  }

  renderNavItems = () => {
    var items = [
      {title:'Support', page:'Support'},
      {title:'Feedback', page:'Feedback'},
      {title:'Get the app', page:'Download'},
    ]
    return(
      <View style={[{marginLeft:GLOBAL.displayMode === 'mobile' ? 0 : -GLOBAL.padding*1}, MainStyles.flexCenter, MainStyles.flexRow]}>
      {items.map((item, index) => this.renderNavItem({item, index}))}
      </View>
    )
  }

  renderNavItem = ({item, index}) => {
    if(item.hide){
      return null
    }else{
      return(
        <Pressable onPress={()=>this.openPage(item)}>
          <View style={[{height:48, paddingHorizontal:GLOBAL.displayMode === 'mobile' ? 10 : GLOBAL.padding*1.5}, MainStyles.flexCenter, MainStyles.flexRow]}>
            <Text style={{fontSize:12, fontFamily:GLOBAL.fonts.bold, color:GLOBAL.style.color, textTransform:'uppercase'}}>
            {item.title}
            </Text>
          </View>
        </Pressable>
      )
    }
  }

  render = () => {
    try{
      return this.state.hide ? null : this.renderContent()
    }catch(e){
      return null
    }
  }

}
