/* @flow */

import React, { PureComponent } from "react";
import {
  View,
  Text,
  Pressable,
  Image,
  TextInput,
  Dimensions,
  Animated,
  SectionList,
  StyleSheet,
} from "react-native";

//Modules
import { Link } from "@react-navigation/native";
import { BlurView } from "expo-blur";
import { createFilter } from "react-search-input";
import { Hoverable } from "react-native-web-hover";

//Components
// import Image from '../general/image.js'
import Tournament from "../tournament/tournament.js";
import Player from "../players/player.js";
import Footer from "./footer.js";
import Scores from "../widgets/scores.js";
import SettingsButton from './SettingsButton.js'

//Helpers
import MainStyles from "../../helpers/mainstyles.js";
import Functions from "../../helpers/functions.js";
import GLOBAL from "../../helpers/global.js";

const purple = "#805EFF";
const dimensions = {
  width: Dimensions.get("window").width,
  height: Dimensions.get("window").height,
};

var items = [
  { title: "Home", link: "/", page: "Home" },
  { title: "Watch", link: "/streams", page: "Streams" },
  { title: "Tournaments", link: "/tournaments", page: "Tournaments" },
  { title: "Rankings", link: "/rankings", page: "Rankings" },
  // {title:'Fantasy', link:'/fantasy', page:'Fantasy'},
  // {title:'App', link:'/download', page:'Download'},
];

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      // darkMode:true,
      showBackground: this.props.showBackground,
      search_term: "",
      showSearch: false,
      loadingSearch: true,
      tournaments_all: [],
      players_all: [],
      showMenu: false,
    };
  }

  async componentDidMount() {
    GLOBAL.clearSearch = this.clearInput;
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.showBackground !== this.props.showBackground) {
      this.setState({ showBackground: this.props.showBackground });
    }
  }

  async componentWillUnmount() {}

  goHome = () => {
    this.props.navigation.navigate("Home");
  };

  showMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  clearInput = () => {
    this.searchInput.clear();
    this.setState({ searchTerm: "" });
  };

  showSearch = () => {
    this.setState({ showSearch: !this.state.showSearch });
  };

  search = () => {
    var { search_term } = this.state;
    if (search_term) {
      this.props.navigation.push("Search", { query: search_term });
    }
  };

  openPage = (page) => {
    GLOBAL.page = page;
    this.props.navigation.navigate(page);
  };

  updateText = (searchTerm) => {
    // if(this.state.gotSearchItems){
    //   this.getSearchItems()
    // }
    this.setState({ searchTerm, gotSearchItems: true });

    if (searchTerm) {
      // GLOBAL.lockScroll(true)
    } else {
      // GLOBAL.lockScroll(false)
    }
  };

  getSearchItems = async () => {
    var tournaments_all = GLOBAL.tournaments_all || [];
    var players_all = GLOBAL.players_all || [];
    // var matches_all = GLOBAL.matches_all || []

    var promises = [0, 1];
    await Promise.all(
      promises.map(async (promise, index) => {
        if (index === 0) {
          var data = await Functions.firestoreAPI(
            "https://us-central1-tennis-lwts.cloudfunctions.net/api_getTournaments?=all?=Tour"
          );
          if (data.data) {
            await Promise.all(
              data.data.map(async (section, index) => {
                await Promise.all(
                  section.data.map(async (tournament, index) => {
                    tournaments_all.push(tournament);
                  })
                );
              })
            );
          }
        } else {
          if (players_all.length === 0) {
            //Get tournaments from API
            var data_2 = await Functions.firestoreAPI(
              "https://us-central1-tennis-lwts.cloudfunctions.net/api_getPlayers"
            );
            players_all = data_2;
          }
        }
      })
    );

    // if(tournaments_all.length === 0){
    //   //Get tournaments from API
    //   var data = await Functions.firestoreAPI("https://us-central1-tennis-lwts.cloudfunctions.net/api_getTournaments?=all?=Tour")
    //   if(data.data){
    //     await Promise.all(data.data.map(async (section, index) => {
    //       await Promise.all(section.data.map(async (tournament, index) => {
    //         tournaments_all.push(tournament)
    //       }))
    //     }))
    //   }
    // }
    //
    // if(players_all.length === 0){
    //   //Get tournaments from API
    //   var data_2 = await Functions.firestoreAPI("https://us-central1-tennis-lwts.cloudfunctions.net/api_getPlayers")
    //   players_all = data_2
    // }

    this.setState({ tournaments_all, players_all, loadingSearch: false });

    GLOBAL.tournaments_all = tournaments_all;
    GLOBAL.players_all = players_all;
  };

  changeMode = () => {
    // GLOBAL.style = GLOBAL.style_light
    // GLOBAL.darkMode = !this.state.darkMode
    // GLOBAL.refresh()
    // this.setState({darkMode:!this.state.darkMode})
  };

  download = () => {
    if (GLOBAL.displayMode === "mobile") {
      GLOBAL.navigation.push("Download");
    } else if (this.props.onPressDownload) {
      this.props.onPressDownload();
    }
  };

  renderContent = () => {
    return (
      <>
        <View
          style={[
            {
              width: "100%",
              marginBottom: 0,
              backgroundColor: GLOBAL.style.content.card,
              zIndex: 900000,
            },
            MainStyles.flexCenter,
          ]}
        >
          {
            // GLOBAL.isMobile ? null : this.renderScores()
          }
          <View
            style={[
              { maxWidth: GLOBAL.maxWidth, width: "100%" },
              MainStyles.flexCenter,
            ]}
          >
            <View
              style={[
                {
                  marginVertical:
                    GLOBAL.padding * (GLOBAL.displayMode !== "web" ? 0.5 : 1),
                  paddingLeft:
                    GLOBAL.displayMode !== "web" ? 10 : GLOBAL.padding,
                  width: "100%",
                },
                MainStyles.flexRow,
                MainStyles.flexBetweenCenter,
              ]}
            >
              {GLOBAL.displayMode === "mobile" ? (
                this.renderMobile()
              ) : (
                <>
                  <View
                    style={[
                      { flex: 1 },
                      MainStyles.flexStartCenter,
                      MainStyles.flexRow,
                    ]}
                  >
                    <View style={{}}>{this.renderLogoText()}</View>
                    {this.renderSearch()}
                    <View style={{}}>{this.renderDownload()}</View>
                    {this.renderSettings()}
                  </View>
                </>
              )}
            </View>
          </View>
          {GLOBAL.isMobile ? null : (
            <View
              style={[
                {
                  width: "100%",
                  backgroundColor: GLOBAL.style.content.card ||
                    "rgba(255,255,255,0.05)" || "#454E56" || "transparent",
                    borderTopWidth:0.2,
                    borderTopColor:GLOBAL.style.content.borderColor
                },
                MainStyles.flexCenter,
              ]}
            >
              <View
                style={[
                  { maxWidth: GLOBAL.maxWidth, width: "100%" },
                  MainStyles.flexCenter,
                ]}
              >
                {this.renderSecondaryNav()}
              </View>
            </View>
          )}
        </View>
        {this.renderMenu()}
      </>
    );
  };

  renderSecondaryNav = () => {
    var items = [
      // {title:'Fantasy', link:'/', page:''},
      { title: "Scores", link: "/", page: "Home" },
      { title: "Tournaments", link: "/", page: "Tournaments" },
      // { title: "Draws", link: "/", page: "Draws" },
      { title: "Rankings", link: "/", page: "Rankings" },
      { title: "News", link: "/", page: "News" },
      { title: "Videos", link: "/", page: "Videos" },
      // { title: "Podcasts", link: "/", page: "Podcasts" },
      { title: "Widgets", link: "/", page: "Widget" },
      // {title:'Get the app', link:'/', page:''},
    ];
    if (GLOBAL.displayMode !== "mobile") {
      return (
        <View
          style={[
            {
              width: "100%",
              padding: GLOBAL.padding,
              paddingVertical:0,
              marginLeft: -GLOBAL.padding * 2,
            },
            MainStyles.flexRow,
            MainStyles.flexStartCenter,
          ]}
        >
          {items.map((item, index) => this.renderSecondaryNavItem(item, index))}
        </View>
      );
    } else {
      return null;
    }
  };

  renderSecondaryNavItem = (item, index) => {
    var active = item.page === this.props.page;
    return (
      <Hoverable style={{}}>
        {({ hovered }) => (
          <Pressable
            onPress={() => this.openPage(item.page)}
            style={{
              paddingHorizontal: GLOBAL.padding,
              marginRight: GLOBAL.padding,
              paddingVertical:12,
              borderBottomWidth:2,
              borderBottomColor:active ? purple : 'transparent'
            }}
          >
            <Text
              style={{
                color: active ? purple : GLOBAL.style.color,
                opacity: active ? 1 : hovered ? 0.4 : 0.67,
                fontFamily: 'ClanOT-Bold',
                letterSpacing: 0.2,
                fontSize: 13,
                textTransform:'uppercase'
              }}
            >
              {item.title}
            </Text>

            {
              // (active) ?
              // <View style={{width:'100%', paddingHorizontal:GLOBAL.padding*2}}>
              //   <View style={{width:'100%', height:3, backgroundColor: 'rgba(255,255,255,0)' || GLOBAL.style.accent || GLOBAL.style.color, position:'absolute', bottom:-GLOBAL.padding/2, left:0}}/>
              // </View>
              // : null
            }
          </Pressable>
        )}
      </Hoverable>
    );
  };

  renderSearch = () => {
    return (
      <View style={{ flex: 1, paddingHorizontal: GLOBAL.padding * 4 }}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            borderRadius: 600,
            overflow: "hidden",
          }}
        >
          <View
            style={[
              {
                height: 40,
                backgroundColor:GLOBAL.style.content.borderColor,
                flex:1,
                overflow: "hidden",
                borderWidth: 0,
              },
              MainStyles.flexRow,
            ]}
          >
            <TextInput
              style={{
                flex: 1,
                padding: GLOBAL.padding / 1.5,
                paddingHorizontal:18,
                fontFamily: GLOBAL.fonts.regular,
                fontSize: 13,
                color:GLOBAL.style.color,
                borderRadius:100,
              }}
              placeholderTextColor={GLOBAL.style.colorSecondary}
              placeholder="Search players, tournaments, news"
              onSubmitEditing={this.search}
              onChangeText={(search_term) => this.setState({ search_term })}
              value={this.state.search_term}
            />
          </View>
          {
            this.state.search_term &&
            <Hoverable style={{}}>
            {({ hovered }) => (
              <Pressable
                onPress={this.search}
                style={[
                  {
                    width: 80,
                    marginRight: -1,
                    height: "100%",
                    backgroundColor: hovered ? "#2c3339" : "#454E56",
                  },
                  MainStyles.flexCenter,
                ]}
              >
                <Image
                  style={{
                    height: 20,
                    width: 20,
                    resizeMode: "contain",
                    tintColor: 'white',
                  }}
                  source={require("../../assets/icons/search-filled.png")}
                />
              </Pressable>
            )}
          </Hoverable>
          }
        </View>
      </View>
    );
  };

  renderScores = () => {
    return <Scores />;
  };

  renderMobile = () => {
    return (
      <View
        style={[
          {
            width: "100%",
            paddingHorizontal:
              GLOBAL.displayMode === "tablet"
                ? GLOBAL.padding
                : GLOBAL.padding / 2,
            paddingVertical: GLOBAL.padding / 2,
          },
          MainStyles.flexBetweenCenter,
          MainStyles.flexRow,
        ]}
      >
        {this.renderLogoText(true)}
        <View style={{flexDirection:'row'}}>
        {this.renderDownload(true)}
        {this.renderSettings(true)}
        </View>
      </View>
    );
  };

  renderButton = (source, onPress) => {
    return (
      <Pressable
        onPress={onPress}
        style={[{ height: 48, width: 48 }, MainStyles.flexCenter]}
      >
        <Image
          style={{
            height: 30,
            width: 30,
            tintColor: GLOBAL.style.color_font,
            resizeMode: "contain",
          }}
          source={source}
        />
      </Pressable>
    );
  };

  renderLogo = () => {
    return (
      <Pressable onPress={this.goHome}>
        <Image
          style={{
            width: GLOBAL.displayMode === "mobile" ? 50 : 60,
            aspectRatio: 799 / 559,
            resizeMode: "contain",
          }}
          source={require("../../images/logo.webp")}
        />
      </Pressable>
    );
  };

  renderLogoText = (small) => {
    var fontSize = small ? 20 : 28;
    var fontSizeSmall = (fontSize / 24) * 16;
    var letterSpacing = 0.5;
    let color = GLOBAL.style.color
    return (
      <Pressable onPress={this.goHome} style={[MainStyles.flexCenterStart]}>
        <View style={[MainStyles.flexRow, MainStyles.flexStartEnd]}>
          <Text
            style={{
              fontSize,
              color,
              fontFamily: "ClanOT-Ultra",
              letterSpacing,
            }}
          >
            TNNS
          </Text>
          {
            // <Text style={{fontSize:fontSizeSmall, color:GLOBAL.colors.pink, opacity:1, fontFamily:GLOBAL.fonts.bold, marginLeft:4, marginBottom:2}}>
            // LIVE
            // </Text>
          }
          {
            <Text
              style={{
                fontSize,
                opacity: 0.85,
                color: purple || GLOBAL.style.accent,
                fontFamily: "ClanOT-Ultra",
                marginLeft: 4,
                letterSpacing,
              }}
            >
              LIVE
            </Text>
          }
        </View>
        <Text
          style={{
            fontSize: 11,
            color,
            opacity: 0.33,
            fontFamily: GLOBAL.fonts.regular,
            marginTop: 0,
          }}
        >
          Built by fans, for fans
        </Text>
      </Pressable>
    );
  };

  renderNavItems = () => {
    return (
      <View
        style={[
          { marginLeft: GLOBAL.padding * 4 },
          MainStyles.flexCenter,
          MainStyles.flexRow,
        ]}
      >
        {items.map((item, index) => this.renderNavItem({ item, index }))}
      </View>
    );
  };

  renderAuthButtons = () => {
    return (
      <View
        style={[
          { marginLeft: GLOBAL.padding * 2 },
          MainStyles.flexCenter,
          MainStyles.flexRow,
        ]}
      >
        {this.renderNavItem({
          item: { title: "Log In", link: "/login", page: "Login" },
        })}
        <View style={{ width: GLOBAL.padding / 2 }} />
        {this.renderNavItem({
          item: {
            title: "Sign up",
            link: "/login",
            page: "Login",
            backgroundColor: GLOBAL.style.color,
            color: GLOBAL.style.backgroundColor,
          },
        })}
      </View>
    );
  };

  openSettings = () => {
    // alert('settings')
  }

  renderSettings = (mobile) => {
    return <SettingsButton mobile={mobile}/>
  }

  renderDownload = (small) => {
    // return(
    //   <Hoverable style={{}}>
    //   {({ hovered }) => (
    //     <Pressable onPress={this.props.onPressDownload} style={[{backgroundColor:"rgba(255,255,255,0.1)", opacity:hovered ? 0.67 : 1, height:40, marginRight:0, paddingHorizontal:GLOBAL.padding/1, borderRadius:GLOBAL.borderRadius}, MainStyles.flexCenter]}>
    //       <Text style={{fontSize:12, color:GLOBAL.style.color, fontFamily:GLOBAL.fonts.black, textTransform:'uppercase'}}>
    //       Get the app
    //       </Text>
    //     </Pressable>
    //   )}
    //   </Hoverable>
    // )
    return (
      <Hoverable style={{}}>
        {({ hovered }) => (
          <Pressable
            onPress={this.download}
            style={[
              {
                backgroundColor: purple || "rgba(255,255,255,0.15)",
                height: small ? 36 : 40,
                marginRight: 0,
                paddingHorizontal: 16,
                borderRadius: 6,
              },
              MainStyles.flexCenter,
            ]}
          >
            <Text
              style={{
                fontSize: 12,
                color: 'white',
                fontFamily: GLOBAL.fonts.black,
                textTransform: "uppercase",
              }}
            >
              Get the app
            </Text>
          </Pressable>
        )}
      </Hoverable>
    );
  };

  renderNavItem = ({ item, index }) => {
    var active = item.page === this.props.page;
    if (item.hide) {
      return null;
    } else {
      return (
        <Hoverable>
          {({ hovered }) => (
            <Pressable onPress={() => this.openPage(item.page)}>
              <View
                style={[
                  {
                    height: 34,
                    paddingHorizontal: GLOBAL.padding * 1,
                    backgroundColor: item.backgroundColor || "transparent",
                    borderRadius: 100,
                  },
                  MainStyles.flexCenter,
                  MainStyles.flexRow,
                ]}
              >
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: GLOBAL.fonts.bold,
                    color: item.color || GLOBAL.style.color,
                    textTransform: "uppercase",
                    letterSpacing: 0.5,
                  }}
                >
                  {item.title}
                </Text>
                {active || hovered ? (
                  <View
                    style={{
                      width: "100%",
                      paddingHorizontal: GLOBAL.padding,
                      position: "absolute",
                      bottom: 1,
                    }}
                  >
                    <View
                      style={{
                        width: "100%",
                        height: 2.0,
                        backgroundColor: active ? GLOBAL.style.accent : GLOBAL.style.color,
                      }}
                    />
                  </View>
                ) : null}
              </View>
            </Pressable>
          )}
        </Hoverable>
      );
    }
  };

  renderIcon = (source) => {
    var size = 16;
    return (
      <Image
        style={{
          height: size,
          width: size,
          resizeMode: "contain",
          tintColor: GLOBAL.style.color_font,
          marginHorizontal: 2.5,
        }}
        source={source}
      />
    );
  };

  renderDarkMode = () => {
    var icon = !this.state.darkMode
      ? require("../../icons/sun.png")
      : require("../../icons/moon.png");
    return (
      <Pressable
        onPress={this.changeMode}
        style={[
          {
            width: 58,
            height: 32,
            padding: 4,
            borderRadius: 100,
            backgroundColor: GLOBAL.style.borderColor,
            marginLeft: GLOBAL.padding * 2,
          },
          !this.state.darkMode
            ? MainStyles.flexStartCenter
            : MainStyles.flexEndCenter,
          MainStyles.flexRow,
        ]}
      >
        <View
          style={[
            {
              height: "100%",
              aspectRatio: 1,
              borderRadius: 100,
              backgroundColor: GLOBAL.style.color,
            },
            MainStyles.flexCenter,
          ]}
        ></View>
        <Image
          style={{
            height: 16,
            aspectRatio: 1,
            resizeMode: "contain",
            tintColor: this.state.darkMode
              ? "rgba(255,255,255,0.5)"
              : "#121212",
            position: "absolute",
            left: 8,
          }}
          source={require("../../icons/sun.png")}
        />
        <Image
          style={{
            height: 16,
            aspectRatio: 1,
            resizeMode: "contain",
            tintColor: !this.state.darkMode
              ? "rgba(255,255,255,0.5)"
              : "#121212",
            position: "absolute",
            right: 8,
          }}
          source={require("../../icons/moon.png")}
        />
      </Pressable>
    );
    // return null
  };

  renderTitle = (title, tooMany, empty) => {
    var secondString = null;
    if (empty) {
      secondString = "No matching results";
    }
    if (tooMany) {
      secondString =
        "There's a lot of results that match that term.  Please try a more specific search term.";
    }
    return (
      <>
        <Text
          style={[
            {
              color: GLOBAL.style.color_font,
              fontSize: 12,
              fontFamily: GLOBAL.fonts.semibold,
              textTransform: "uppercase",
              letterSpacing: 2,
              marginBottom: GLOBAL.padding / (secondString ? 0 : 2),
              marginTop: GLOBAL.padding,
              paddingLeft: GLOBAL.padding,
            },
          ]}
        >
          {title}
        </Text>
        {!secondString ? null : (
          <Text
            style={[
              {
                color: GLOBAL.style.color_font,
                fontSize: 12,
                fontFamily: GLOBAL.fonts.regular,
                marginBottom: GLOBAL.padding / 2,
                marginTop: GLOBAL.padding / 4,
                paddingLeft: GLOBAL.padding,
              },
            ]}
          >
            {secondString}
          </Text>
        )}
      </>
    );
  };

  renderTournament = ({ index, item }) => {
    return <Tournament key={item.id} index={index} data={item} />;
  };

  //
  renderMenu = () => {
    if (this.state.showMenu) {
      var height = 70;
      return (
        <View
          style={[
            {
              height: dimensions.height - height,
              position: "absolute",
              top: height,
              backgroundColor: GLOBAL.style.gradient[0],
              width: dimensions.width,
              zIndex: 9000000,
              paddingTop: GLOBAL.padding,
            },
            MainStyles.flexStartStart,
          ]}
        >
          {items.map((item, index) =>
            this.renderMobileNavItem({ item, index })
          )}
          <View style={{ width: "100%", position: "absolute", bottom: 0 }}>
            <Footer />
          </View>
        </View>
      );
    } else {
      return null;
    }
  };

  renderMobileNavItem = ({ item, index }) => {
    if (item.hide) {
      return null;
    } else {
      return (
        <Pressable onPress={() => this.openPage(item.page)}>
          <View
            style={[
              { height: 55, paddingHorizontal: GLOBAL.padding },
              MainStyles.flexCenter,
              MainStyles.flexRow,
            ]}
          >
            <Text
              style={{
                fontSize: 16,
                fontFamily: GLOBAL.fonts.bold,
                color: GLOBAL.style.color_font,
                letterSpacing: 2,
                textTransform: "uppercase",
              }}
            >
              {item.title}
            </Text>
            {!item.appIcons ? null : (
              <View
                style={[
                  { marginLeft: GLOBAL.padding / 3 },
                  MainStyles.flexRow,
                  MainStyles.flexCenter,
                ]}
              >
                {this.renderIcon(require("../../icons/apple.png"))}
                {this.renderIcon(require("../../icons/android.png"))}
              </View>
            )}
          </View>
        </Pressable>
      );
    }
  };

  render = () => {
    try {
      return this.renderContent();
    } catch (e) {
      return null;
    }
  };
}
