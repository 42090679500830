import React, { useEffect, useState, useRef } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import Functions from "../../helpers/functions";
import GLOBAL from "../../helpers/global";
import Tabs from "../../components/general/tabs.js";
import Player from '../../components/general/player.js'

const Table = ({data}) => {
  //Cells
  let paddingHorizontal = 6;
  let paddingVertical = 6;
  let cellHeight = 42;

  let title = data?.title;
  let table = data?.table || {};
  let { header, legend, rows, showPos } = table;

  // const navigation = useNavigation()
  const horizontalListRef = useRef(null);
  const [isSticky, setSticky] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const style = GLOBAL.style
  const fonts = GLOBAL.fonts

  let textStyle = {
    fontSize: 12,
    color: style.color,
    fontFamily: fonts.regular,
  };

  useEffect(() => {}, []);

  const renderHeader = () => {
    return renderItem(header, 0, true);
  };

  const renderItem = (item, index, header) => {
    return (
      <View
        style={[
          {
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",
            paddingHorizontal: 16,
            paddingVertical: header ? 8 : 12,
            borderTopWidth: header ? 0 : 1,
            borderColor: style.borderColor,
          },
          header
            ? {
                backgroundColor: item.header
                  ? style.section_title?.backgroundColor
                  : "transparent",
              }
            : {},
        ]}
      >
        {!item.pos ? null : (
          <Text
            style={[
              textStyle,
              header
                ? {
                    fontSize: 11,
                    fontFamily: fonts.bold,
                    color: style.section_title?.color,
                  }
                : {},
              { marginRight: 8 },
            ]}
          >
            {item.pos}
          </Text>
        )}
        <View style={{ flex: 1, alignItems: "flex-start", justifyContent:'center', height:'100%' }}>
          {item.header?.type === "player" ? (
            renderPlayer(item?.header?.data, index)
          ) : (
            <Text
              style={[
                textStyle,
                header
                  ? {
                      fontFamily: fonts.bold,
                      color: style.section_title?.color,
                    }
                  : {},
              ]}
            >
              {item?.header?.value}
            </Text>
          )}
        </View>
        {(item?.values || []).map((item2, index2) =>
          renderItemValue(item2, index2, header)
        )}
      </View>
    );
  };

  const renderPlayer = (item, index) => {
    return (
      <View style={{ height:'100%', flex:1, justifyContent:'center', paddingRight: 16 }}>
        <Player data={item} index={index} small noBorder noPadding/>
      </View>
    );
  };

  const renderItemValue = (item, index, header) => {
    // console.log(item)//
    return (
      <View
        style={{
          width: item.width || 52,
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Text
          style={[
            textStyle,
            header
              ? {
                  fontSize: 11,
                  fontFamily: fonts.bold,
                  opacity: 1,
                  color: style.section_title?.color,
                }
              : {},
          ]}
        >
          {item.value}
        </Text>
        {(
          <Text style={[textStyle, { opacity: 0.5, marginTop: 2 }]}>
            {item.caption}
          </Text>
        )}
      </View>
    );
  };

  const renderLegend = () => {
    return (
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>Legend</Text>
      </View>
    );
  };

  try {
    return (
      <View style={{ width: "100%", paddingHorizontal: 0 }}>
        <View
          style={{
            width: "100%",
            // borderWidth: 1,
            // borderColor: style.borderColor,
            // borderRadius: 8,
            overflow: "hidden",
          }}
        >
          {renderHeader()}
          {(rows || []).map((item, index) => renderItem(item, index))}
        </View>
      </View>
    );
  } catch (e) {
    console.log(e);
    return null;
  }
};

const Standings = ({ id }) => {
  const [state, setState] = useState({ loading: true });

  useEffect(() => {
    setup();
  }, []);

  const setup = async () => {
    const params = {
      mode: "standings",
      id,
    };

    const data = await Functions.tnnsAPI(params);
    if (!data?.data) return;
    // console.log(data.data);
    setState({
      loading: false,
      ...data.data,
      format: state.format || data?.data?.first_format,
      phase: data.data.tab_data[data?.data?.first_format].first_phase,
    });
  };

  const renderContent = () => {
    return null;
  };

  if (state.loading) {
    return (
      <View
        style={{
          width: "100%",
          minHeight: 200,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="small" color={GLOBAL.style.color} />
      </View>
    );
  }

  const changeFormat = (format) => {
    // console.log(format)
    setState({
      ...state,
      format: format.key,
      phase: state.tab_data[format.key].first_phase,
    });
  };

  const changePhase = (phase) => {
    setState({ ...state, phase: phase.key });
  };

  const renderFormat = () => {
    const activeFormat = state.tab_data && state.tab_data[state.format];
    if (!activeFormat) return null;

    const { phases, phase_data } = activeFormat;
    const activePhaseData = phase_data[state.phase] || [];
    // console.log(activePhaseData.standings);
    return (
      <View style={{ width: "100%" }}>
        {/* {<Tabs
          backgroundColor="transparent"
          tabs={phases || []}
          onChange={changePhase}
          active_tab={state.phase}
          secondary
          disableBorder
          noBorder
        />} */}
        {activePhaseData?.standings?.length &&
          activePhaseData?.standings.map((obj) => renderStandings(obj))}
      </View>
    );
  };

  const renderStandings = (data) => {
    return (
      <View style={{ width: "100%" }}>
        {data.groups.map((obj) => renderGroup(obj))}
      </View>
    );
  };

  const renderGroup = (data) => {
    // console.log(data);
    return (
      <View style={{ width: "100%" }}>
        <Table data={data} />
      </View>
    );
  };

  return (
    <View style={{ width: "100%", minHeight: 200 }}>
      {/* {<Tabs
        tabs={state.formats || []}
        onChange={changeFormat}
        active_tab={state.format}
        secondary
        disableBorder
        noBorder
      />} */}
      {renderFormat()}
    </View>
  );
};

export default Standings;
