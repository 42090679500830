/* @flow */

import React, { PureComponent } from "react";
import { View, Text, Pressable, Image } from "react-native";

//Modules

//Components

//Helpers
import MainStyles from "../../helpers/mainstyles.js";
import GLOBAL from "../../helpers/global.js";

export default class MyComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      subtitle: this.props.subtitle,
      players: this.props.players,
      type: this.props.type,
    };
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.title !== this.props.title) {
      this.setState({ title: this.props.title });
    }
    if (prevProps.subtitle !== this.props.subtitle) {
      this.setState({ subtitle: this.props.subtitle });
    }
    if (prevProps.players !== this.props.players) {
      this.setState({ players: this.props.players });
    }
    if (prevProps.type !== this.props.type) {
      this.setState({ type: this.props.type });
    }
  };

  render = () => {
    var { title, players, type, subtitle } = this.state;
    var { mode, list } = this.props;

    var color = GLOBAL.style.section_title?.color || "rgba(51,51,51,1.0)";

    var style = {
      fontFamily: GLOBAL.fonts.bold,
      color,
      fontSize: 12,
      textTransform: "uppercase",
    };

    var flex = 0.5;
    if (type === "bio") {
      flex = 0.5;
    }

    var borderRadius = GLOBAL.isMobile ? 0 : 0;
    var borderWidth = GLOBAL.isMobile ? 0 : 0;
    var backgroundColor = GLOBAL.style.section_title?.background || "rgba(245,245,245,1)" || GLOBAL.style.backgroundColor;
    // var height = 4

    var logo = null;
    var aspectRatio = 1.5;
    // if(title.includes('WTA')){
    //   logo = GLOBAL.logos.wta
    // }else if(title.includes('Challenger')){
    //   logo = GLOBAL.logos.challenger
    // }else if(title.includes('ATP')){
    //   logo = GLOBAL.logos.atp
    // }else if(title.toUpperCase().includes('ITF')){
    //   logo = GLOBAL.logos.itf
    // }

    if (!title) {
      return null;
    } else {
      try {
        if (mode === "match_bios") {
          return (
            <Pressable
              onPress={this.props.onPress || null}
              style={[
                {
                  width: "100%",
                  paddingVertical: GLOBAL.padding / 2,
                  backgroundColor,
                  paddingHorizontal: GLOBAL.padding,
                  borderRadius,
                  borderWidth,
                  borderColor: GLOBAL.style.borderColor,
                },
                MainStyles.flexStartCenter,
                MainStyles.flexRow,
              ]}
            >
              <Text style={[style, { flex: 0.33 }]}>{title}</Text>
              {!players ? null : (
                <>
                  {!players[0] ? null : (
                    <Text
                      style={[
                        style,
                        { minWidth: this.props.minWidth, flex: 0.33 },
                      ]}
                    >
                      {players[0]}
                    </Text>
                  )}
                  {!players[1] ? null : (
                    <Text
                      style={[
                        style,
                        { minWidth: this.props.minWidth, flex: 0.33 },
                      ]}
                    >
                      {players[1]}
                    </Text>
                  )}
                </>
              )}
              {!subtitle ? null : (
                <Text
                  style={[
                    style,
                    {
                      flex: 1,
                      color: GLOBAL.style.color_secondary,
                      textAlign: "right",
                      fontFamily: GLOBAL.fonts.medium,
                      textTransform: "none",
                    },
                  ]}
                  numberOfLines={1}
                >
                  {subtitle}
                </Text>
              )}
            </Pressable>
          );
        } else {
          // return null
          return (
            <Pressable
              onPress={this.props.onPress || null}
              style={[
                {
                  width: "100%",
                  paddingVertical: list ? 8 : GLOBAL.padding / 1.5,
                  backgroundColor,
                  paddingHorizontal: GLOBAL.padding,
                  borderBottomWidth: 0,
                  borderBottomColor: GLOBAL.style.cardColor,
                  borderTopWidth: 0,
                  borderTopColor: GLOBAL.style.cardColor,
                },
                MainStyles.flexStartCenter,
                MainStyles.flexRow,
              ]}
            >
              {!logo ? null : (
                <Image
                  style={{
                    height: 18,
                    aspectRatio,
                    resizeMode: "contain",
                    marginRight: GLOBAL.padding / 2,
                  }}
                  source={{ uri: logo }}
                />
              )}

              <Text style={[style, { flex: 1 }]} numberOfLines={1}>
                {title}
              </Text>
              {!players ? null : (
                <>
                  {!players[0] ? null : (
                    <Text
                      style={[style, { minWidth: this.props.minWidth, flex }]}
                    >
                      {players[0]}
                    </Text>
                  )}
                  {!players[1] ? null : (
                    <Text
                      style={[style, { minWidth: this.props.minWidth, flex }]}
                    >
                      {players[1]}
                    </Text>
                  )}
                </>
              )}
              {!subtitle ? null : (
                <Text
                  style={[
                    style,
                    {
                      flex: 1,
                      color: GLOBAL.style.color_secondary,
                      textAlign: "right",
                      fontFamily: GLOBAL.fonts.medium,
                      textTransform: "none",
                    },
                  ]}
                  numberOfLines={1}
                >
                  {subtitle}
                </Text>
              )}
            </Pressable>
          );
        }
      } catch (e) {
        return null;
      }
    }
  };
}
