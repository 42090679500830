/* @flow */

import React, { PureComponent } from "react";
import { View, Text } from "react-native";

//Modules

// import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

//Components
import Tabs from "../components/general/tabs.js";
import Background from "../components/general/background.js";
import Loading from "../components/general/loading.js";
import BottomNavbar from "../components/navigation/bottomnavbar.js";

import Matches from "./tournament/matches.js";
import Draws from "./tournament/draws.js";
import Info from "./tournament/info.js";
import Players from "./tournament/players.js";
import Standings from "./tournament/standings.js";

//Helpers
import useList from "../helpers/useList";
import MainStyles from "../helpers/mainstyles.js";
import GLOBAL from "../helpers/global.js";
import Functions from "../helpers/functions.js";

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeView: "Live",
      id: this.props.route?.params?.id,
    };
  }

  componentDidMount = async () => {
    setTimeout(() => {
      this.setup();
    }, 350);
  };

  componentWillUnmount = async () => {};

  setup = async () => {
    var { id } = this.state;
    var params = {
      id,
      mode: "tournament",
    };

    const displayList = useList();

    // if(GLOBAL.__DEV__) params.url = 'http://localhost:5001/tennis-lwts/us-central1/gen2_web_api'

    var data_r = await Functions.tnnsAPI(params);
    var data = data_r?.data || {};

    var {
      tabs,
      first_tab,
      first_data,
      header,
      draws,
      information,
      formats,
      formats_summary,
      draws_message,
      seo,
    } = data;

    // console.log(data)

    // console.log(tabs)

    if (data?.id) {
      id = data.id;
    }

    var categories = seo.categories || {};
    var categories_string = Object.keys(categories)
      .join(" & ")
      .replace("_", "");

    var title =
      seo.season +
      " " +
      seo.title +
      " " +
      categories_string.toUpperCase() +
      " - Tournament Live Scores, Results, Streams, Draws & Schedules";
    var description =
      "Live scores, results, live streams, draws, schedules & more for the " +
      seo.season +
      " " +
      seo.title;

    this.props.navigation.setOptions({ title });

    //Set the first tab
    if(this.props.route.params.content) first_tab = this.props.route.params.content.toLowerCase()
    if(first_tab) this.props.navigation.setParams({ content: first_tab.toLowerCase() });

    document.title = title;

    this.setState({
      displayList,
      title,
      description,
      first_data,
      data,
      draws,
      draws_message,
      header,
      tabs,
      information,
      formats,
      formats_summary,
      first_tab,
      activeView: first_tab.toLowerCase(),
      id,
      loading: false,
    });
  };

  changeTab = (tab) => {
    this.props.navigation.setParams({ content: tab.id });
    // this.setState({ activeView: tab.id });
  };

  componentDidUpdate = (prevProps) => {
    if(prevProps.route.params.content !== this.props.route.params.content){
      this.setState({ activeView: this.props.route.params.content })
    }
  };

  renderContent = () => {
    var { loading } = this.state;
    // var title = "Live Tennis Scores, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    return (
      <>
        {
          // <Header title={this.state.title} description={this.state.description}/>
        }
        <Background navigation={this.props.navigation} page={"Scores"}>
          {loading ? <Loading /> : this.renderMain()}
        </Background>
        <BottomNavbar />
      </>
    );
  };

  renderHeader = () => {
    var { header } = this.state;
    return (
      <View
        style={[
          {
            width: "100%",
            paddingVertical: GLOBAL.padding,
            borderColor: GLOBAL.style.borderColor,
            marginBottom: GLOBAL.padding * 0,
          },
          MainStyles.flexCenter,
        ]}
      >
        <View
          style={[
            { width: "100%", maxWidth: GLOBAL.maxWidth },
            MainStyles.flexRow,
            MainStyles.flexStartCenter,
          ]}
        >
          <View
            style={[
              { width: "100%", paddingHorizontal: GLOBAL.padding },
              MainStyles.flexCenterStart,
            ]}
          >
            <Text
              style={{
                fontSize: 20,
                color: GLOBAL.style.color,
                fontFamily: GLOBAL.fonts.black,
                marginBottom: GLOBAL.padding / 4,
                textTransform: "uppercase",
              }}
            >
              {header?.title}
            </Text>
            <Text
              style={{
                fontSize: 14,
                color: GLOBAL.style.color,
                opacity: 0.67,
                fontFamily: GLOBAL.fonts.regular,
              }}
            >
              {header?.subtitle}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  renderMain = () => {
    var {
      tabs,
      displayList,
      first_tab,
      first_data,
      id,
      draws,
      formats,
      formats_summary,
      information,
      draws_message,
      activeView,
    } = this.state;

    var tabs = [
      { id: "summary", title: "All" },
      { id: "live", title: "Live" },
      { id: "completed", title: "Completed" },
      { id: "scheduled", title: "Scheduled" },
      { id: "draws", title: "Draws" },
      { id: "standings", title: "Standings" },
      { id: "players", title: "Players" },
      { id: "info", title: "Info" },
    ].filter(obj => !!tabs[`tab_${obj.id}`])

    let header = null;
    if (displayList) {
      header = (
        <View style={[{ width: "100%" }, MainStyles.flexStartCenter]}>
          {this.renderHeader()}
          <View style={{ maxWidth: GLOBAL.maxWidth, width: "100%" }}>
            <View
              style={[
                {
                  width: "100%",
                  borderTopWidth: 1,
                  borderTopColor: GLOBAL.style.content?.borderColor,
                  marginVertical: 0,
                },
                MainStyles.flexCenter,
              ]}
            >
              <Tabs
                tabs={tabs}
                onChange={this.changeTab}
                active_tab={activeView}
              />
            </View>
          </View>
        </View>
      );
    }

    var render = null;
    if (activeView === "live") {
      render = (
        <Matches
          listHeader={header}
          id={id}
          first_data={first_data?.live}
          submode="live"
        />
      );
    } else if (activeView === "completed") {
      render = (
        <Matches
          listHeader={header}
          id={id}
          first_data={first_data?.completed}
          submode="completed"
        />
      );
    } else if (activeView === "scheduled") {
      render = (
        <Matches
          listHeader={header}
          id={id}
          first_data={first_data?.scheduled}
          submode="scheduled"
        />
      );
    } else if (activeView === "draws") {
      render = (
        <Draws
          listHeader={header}
          id={id}
          draws={draws}
          draws_message={draws_message}
        />
      );
    } else if (activeView === "standings") {
      render = (
        <Standings
          listHeader={header}
          id={id}
          data={first_data?.standings}
        />
      );
    } else if (activeView === "summary") {
      render = (
        <Matches
          listHeader={header}
          id={id}
          submode="summary"
          theme="daily"
          // new
          formats={formats_summary}
        />
      );
    } else if (activeView === "players") {
      render = (
        <Players listHeader={header} id={id} formats={formats_summary} />
      );
    } else if (activeView === "info") {
      render = <Info listHeader={header} id={id} data={information} />;
    }

    if (displayList) {
      return <View style={{ flex: 1, width: "100%" }}>{render}</View>;
    }

    return (
      <View style={[{ width: "100%" }, MainStyles.flexStartCenter]}>
        {this.renderHeader()}
        <View style={{ maxWidth: GLOBAL.maxWidth, width: "100%" }}>
          <View
            style={[
              {
                width: "100%",
                borderTopWidth: 1,
                borderTopColor: GLOBAL.style.content?.borderColor,
                marginVertical: 0,
              },
              MainStyles.flexCenter,
            ]}
          >
            <Tabs
              tabs={tabs}
              onChange={this.changeTab}
              active_tab={activeView}
            />
          </View>
          <View
            style={[
              {
                width: "100%",
                borderTopWidth: 1,
                borderTopColor: GLOBAL.style.content?.borderColor,
                borderRadius: 0,
                maxWidth: GLOBAL.maxWidth,
              },
              MainStyles.flexRow,
              MainStyles.flexCenterStart,
            ]}
          >
            {render}
            {
              // this.renderRight()
            }
          </View>
        </View>
      </View>
    );
  };

  renderRight = () => {
    var { information, id } = this.state;
    return (
      <View
        style={{
          width: 600,
          minHeight: 200,
          borderLeftWidth: 1,
          borderLeftColor: GLOBAL.style.borderColor,
        }}
      >
        <Text
          style={{
            fontSize: 20,
            color: GLOBAL.style.color,
            fontFamily: GLOBAL.fonts.black,
            textTransform: "uppercase",
            paddingLeft: GLOBAL.padding,
            marginBottom: GLOBAL.padding,
            marginTop: GLOBAL.padding,
          }}
        >
          Info
        </Text>
        <Info id={id} data={information} />
      </View>
    );
  };

  render = () => {
    var { loading } = this.state;

    try {
      return this.renderContent();
    } catch (e) {
      return null;
    }
  };
}
