/* @flow */

import React, { PureComponent } from "react";
import { View, Text, Pressable, Alert } from "react-native";

//Modules
import ReactCountryFlag from "react-country-flag";
import { Hoverable } from "react-native-web-hover";

//Components
import Icon from "../../components/general/icon.js";

//Helpers
import MainStyles from "../../helpers/mainstyles.js";
import GLOBAL from "../../helpers/global.js";
import Functions from "../../helpers/functions.js";

export default class MyComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      index: this.props.index,
    };
  }

  async componentDidMount() {
    var { data } = this.props;
    // console.log(data)
    // var notifications = await Functions.checkPlayerNotification(data.id)
    // var favorite = await Functions.checkFavoritePlayer(data.id)
    // this.setState({notifications, favorite, id:data.id})
    this.setState({ id: data.id });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
    if (prevProps.index !== this.props.index) {
      this.setState({ index: this.props.index });
    }
  }

  async componentWillUnmount() {}

  openPlayer = () => {
    var { data } = this.props;
    var id = data.id;
    if (id.includes("gs:")) id = `g-${data.id.split("competitor:")[1]}`;
    if (id.includes("sr:")) id = data.id.split("competitor:")[1];

    if (GLOBAL.navigation) {
      GLOBAL.navigation.push("Player", { id });
    } else {
      Functions.openURL("https://tnnslive.com/player/" + id);
    }
  };

  favorite = (nativeEvent, disableReferred) => {
    var { id } = this.state;

    Functions.toggleFavoritePlayer(id, !this.state.favorite);

    if (!this.state.notifications && !this.state.favorite && !disableReferred) {
      this.notifications(null, true);
    }

    this.setState({ favorite: !this.state.favorite });
  };

  notifications = (nativeEvent, disableReferred) => {
    this.toggleNotifications();
    if (!this.state.favorite && !disableReferred && !this.state.notifications) {
      this.favorite(null, true);
    }
  };

  toggleNotifications = async () => {
    var { notifications, id } = this.state;

    var hidePrompt = await Functions.getAsyncData(
      "prompts/playerNotifications"
    );

    if (!hidePrompt && !notifications) {
      Alert.alert(
        "Get notifications for this player?",
        "We'll send you a notification when their matches start, finish and when each set finishes",
        [
          {
            text: "Ok + don't show me this again",
            onPress: async () => this.changeNotifications(true),
          },
          { text: "Ok", onPress: () => this.changeNotifications(false) },
          { text: "Cancel", style: "cancel" },
        ]
      );
    } else {
      this.changeNotifications(false);
    }
  };

  changeNotifications = async (disablePrompt) => {
    var { notifications, id, data } = this.state;

    if (disablePrompt) {
      await Functions.setAsyncData("prompts/playerNotifications", true);
    }

    try {
      var success = await Functions.toggleNotificationsPlayer(
        id,
        !notifications,
        data
      );
      if (success) {
        await this.setState({ notifications: !notifications });
      }
    } catch (e) {
      return null;
    }
  };

  //

  renderRanking = (string, movement) => {
    var color = movement > 0 ? GLOBAL.colors.teal : GLOBAL.colors.pink;
    var movement_prefix = movement > 0 ? "+" : "";
    return (
      <View
        style={[
          MainStyles.flexRow,
          MainStyles.flexStartCenter,
          { width: 50, marginRight: GLOBAL.padding },
        ]}
      >
        <Text
          style={{
            fontSize: 14,
            color: GLOBAL.style.color,
            fontFamily: GLOBAL.fonts.regular,
          }}
        >
          {string}
        </Text>
        {!movement ? null : (
          <Text
            style={{
              fontSize: 14,
              color,
              fontFamily: GLOBAL.fonts.regular,
              marginLeft: GLOBAL.padding / 2,
            }}
          >
            {movement_prefix + movement}
          </Text>
        )}
      </View>
    );
  };

  renderName = () => {
    var { data } = this.state;
    return (
      <View style={{ flex: 1 }}>
        <View
          style={[
            { flex: 1, paddingRight: GLOBAL.padding },
            MainStyles.flexRow,
            MainStyles.flexStartCenter,
          ]}
        >
          {/* {!data?.flag && data?.country_code ? this.renderFlag(data.country_code) : null} */}
          {data?.flag ? this.renderFlag(data.flag) : null}
          {data?.flags ? this.renderFlags(data.flags) : null}
          <Text
            style={{
              fontSize: 14,
              color: GLOBAL.style.color,
              fontFamily: GLOBAL.fonts.regular,
            }}
          >
            {data.name}
          </Text>
        </View>
        {data.subtitle ? (
          <Text
            style={{
              fontSize: 12,
              marginTop:4,
              color: GLOBAL.style.color,
              fontFamily: GLOBAL.fonts.regular,
              opacity:0.5,
            }}
          >
            {data.subtitle}
          </Text>
        ) : null}
      </View>
    );
  };

  renderFlags = (flags) => {
    var render = null;

    var width = 16;
    var height = 12;

    if (flags.length === 2) {
      width = 10;
      height = 7.14;
      render = (
        <>
          <View style={{ position: "absolute", bottom: -1, left: 0 }}>
            {!flags[0] ? null : (
              <ReactCountryFlag
                countryCode={GLOBAL.flag_corrections[flags[0]] || flags[0]}
                svg
                style={this.props.style || { width, height }}
                title={flags[0]}
              />
            )}
          </View>
          <View style={{ position: "absolute", top: -1, right: width / 2 }}>
            {!flags[1] ? null : (
              <ReactCountryFlag
                countryCode={GLOBAL.flag_corrections[flags[1]] || flags[1]}
                svg
                style={this.props.style || { width, height }}
                title={flags[1]}
              />
            )}
          </View>
        </>
      );
    } else if (flags.length === 1 && flags[0]) {
      render = (
        <ReactCountryFlag
          countryCode={GLOBAL.flag_corrections[flags[0]] || flags[0]}
          svg
          style={this.props.style || { width, height }}
          title={flags[0]}
        />
      );
    }

    return <View style={{ width: 26 }}>{render}</View>;
  };

  renderFlag = (flag) => {
    if (flag) {
      return (
        <View style={{ marginRight: 10 }}>
          <ReactCountryFlag
            countryCode={GLOBAL.flag_corrections[flag] || flag}
            svg
            style={
              this.props.style || {
                width: 16,
                height: 12,
                marginTop: 1,
                borderRadius: 2,
              }
            }
            title={flag}
          />
        </View>
      );
    } else {
      return null;
    }
  };

  renderPoints = (string) => {
    return (
      <Text
        style={{
          fontSize: 14,
          color: GLOBAL.style.color,
          fontFamily: GLOBAL.fonts.regular,
          textAlign: "right",
        }}
      >
        {string}
      </Text>
    );
  };

  renderFavorite = () => {
    return (
      <View
        style={[
          {
            marginRight: -GLOBAL.padding / 2,
            position: "absolute",
            right: GLOBAL.padding,
          },
          MainStyles.flexRow,
          MainStyles.flexCenter,
        ]}
      >
        {!this.props.allButtons ? null : (
          <Pressable
            onPress={this.notifications}
            style={{
              padding: GLOBAL.padding / 2,
              marginRight: GLOBAL.padding / 2,
            }}
          >
            <Icon
              size={18}
              opacity={this.state.notifications ? 1 : 0.5}
              filled={this.state.notifications}
              icon="notification"
            />
          </Pressable>
        )}
        <Pressable
          onPress={this.favorite}
          style={{ padding: GLOBAL.padding / 2 }}
        >
          <Icon
            size={18}
            opacity={this.state.favorite ? 1 : 0.5}
            filled={this.state.favorite}
            icon="star"
          />
        </Pressable>
      </View>
    );
  };

  render = () => {
    var { index, data } = this.state;
    var backgroundColor =
      index % 2 ? GLOBAL.style.background_alternating : "transparent";
    // console.log(data)
    try {
      return (
        <Hoverable style={{}}>
          {({ hovered }) => (
            <Pressable
              onPress={this.openPlayer}
              key={data.id}
              style={[
                {
                  width: "100%",
                  backgroundColor: hovered ? GLOBAL.style.hover : "transparent",
                  borderTopColor: GLOBAL.style.content?.borderColor,
                  borderTopWidth: index > 0 && !this.props.noBorder ? 1 : 0,
                  paddingVertical: this.props.noPadding
                    ? 0
                    : GLOBAL.padding / 1,
                  paddingHorizontal: this.props.noPadding ? 0 : GLOBAL.padding,
                },
                MainStyles.flexStartCenter,
                MainStyles.flexRow,
              ]}
            >
              {data?.rank ? this.renderRanking(data.rank, data.movement) : null}
              {this.renderName()}
              {data?.points ? this.renderPoints(data.points) : null}
              {data?.seed ? this.renderPoints(data.seed) : null}
              {
                // data?.controls?.favorite ? this.renderFavorite() : null
              }
            </Pressable>
          )}
        </Hoverable>
      );
    } catch (e) {
      return null;
    }
  };
}
