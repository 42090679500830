import * as React from "react";
import { View, Text, Image } from "react-native";
import Dropdown from "@mui/joy/Dropdown";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Switch from "@mui/joy/Switch";
import ListDivider from "@mui/joy/ListDivider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import GLOBAL from "../../helpers/global";
const SettingsButton = ({ mobile }) => {
  const [oddsEnabled, setOddsEnabled] = React.useState(
    localStorage.getItem("oddsEnabled") === "true"
  );
  const [defaultOddsFormat, setDefaultOddsFormat] = React.useState("decimal");
  const [oddsFormat, setOddsFormat] = React.useState(
    localStorage.getItem("oddsFormat")
  );
  const [appearanceSetting, setAppearanceSetting] = React.useState(
    localStorage.getItem("appearance")
  );
  const [location, setLocation] = React.useState("");

  const odds_formats = [
    { value: "decimal", label: "Decimal" },
    { value: "fractional", label: "Fractional" },
    { value: "american", label: "American" },
    { value: "implied_probability", label: "Implied probability" },
    { value: "probability", label: "Probability" },
  ];

  const appearance_options = [
    { value: "system", label: "System" },
    { value: "light", label: "Light" },
    { value: "dark", label: "Dark" },
  ];

  const textStyle = {
    fontFamily: "Inter-Regular",
    fontSize: 16,
    color: "black",
  };

  React.useEffect(() => {
    if (!oddsFormat) {
      determineLocationByTimezone();
    }
  }, []);

  //   React.useEffect(()=>{
  //     // if(location){
  //     //     alert(location)
  //     // }
  //     alert(defaultOddsFormat)
  //   },[defaultOddsFormat])

  const determineLocationByTimezone = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Timezones commonly associated with the US, Canada, UK, and Ireland
    const usTimeZones = [
      "America/New_York",
      "America/Chicago",
      "America/Denver",
      "America/Los_Angeles",
      "America/Phoenix",
      "America/Anchorage",
      "America/Honolulu",
    ];

    const canadaTimeZones = [
      "America/Toronto",
      "America/Vancouver",
      "America/Montreal",
      "America/Halifax",
      "America/Edmonton",
    ];

    const ukTimeZones = [
      "Europe/London",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/Isle_of_Man",
    ];

    const irelandTimeZones = ["Europe/Dublin"];

    // Check which region the user's timezone belongs to
    if (usTimeZones.includes(timeZone)) {
      setLocation("United States");
      setDefaultOddsFormat("american");
    } else if (canadaTimeZones.includes(timeZone)) {
      setLocation("Canada");
      setDefaultOddsFormat("american");
    } else if (ukTimeZones.includes(timeZone)) {
      setLocation("United Kingdom");
      setDefaultOddsFormat("fractional");
    } else if (irelandTimeZones.includes(timeZone)) {
      setLocation("Ireland");
      setDefaultOddsFormat("fractional");
    } else {
      setLocation("Outside US, Canada, UK, Ireland");
      setDefaultOddsFormat("decimal");
    }
  };

  const changeAppearance = (event) => {
    localStorage.setItem("appearance", event.target.value);
    window.location.reload()
  };

  const changeOddsMode = (event) => {
    setOddsEnabled(event.target.checked);
    localStorage.setItem("oddsEnabled", event.target.checked);
    if (event.target.checked && !oddsFormat) {
      //Also set the default odds format based on location
      changeOddsFormat({ target: { value: defaultOddsFormat } });
    }
    fireSessionStorageChange("oddsEnabled", event.target.checked);
  };

  const changeOddsFormat = (event) => {
    setOddsFormat(event.target.value);
    localStorage.setItem("oddsFormat", event.target.value);
    fireSessionStorageChange("oddsFormat", event.target.value);
  };

  const fireSessionStorageChange = (key, value) => {
    window.dispatchEvent(
      new CustomEvent("localStorageChange", {
        detail: { key, value },
      })
    );
  };

  const size = mobile ? 36 : 40;

  return (
    <View style={{ marginLeft: mobile ? 12 : 16 }}>
      <Dropdown>
        <MenuButton
          //   style={{backgroundColor:'rgba(255,255,255,0.1)', height:32, aspectRatio:1, borderWidth:0, borderRadius:100, marginLeft:24}}
          sx={{
            backgroundColor: GLOBAL.style.borderColor,
            height: size,
            width: size,
            borderWidth: 0,
            borderRadius: 400,
            marginLeft: 0,
            "&:hover": {
              backgroundColor: "rgba(255,255,255,0.2)", // Change background color on hover
            },
          }}
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: "outlined", color: "neutral" } }}
        >
          <Image
            style={{
              height: "50%",
              aspectRatio: 1,
              resizeMode: "contain",
              tintColor: GLOBAL.style.color,
            }}
            source={require("../../assets/icons/settings-alt.png")}
          />
        </MenuButton>
        <Menu placement="bottom-end">
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              paddingHorizontal: 12,
              paddingVertical: 8,
            }}
          >
            <FormLabel style={{ marginRight: 16 }}>Show odds</FormLabel>
            <Switch checked={oddsEnabled} onChange={changeOddsMode} size="lg" />
          </View>
          {oddsEnabled && (
            <>
              <ListDivider />
              <View style={{ paddingHorizontal: 12, paddingVertical: 8 }}>
                <FormLabel
                  style={{
                    opacity: oddsEnabled ? 0.5 : 0.35,
                    marginBottom: 12,
                  }}
                >
                  Odds formats
                </FormLabel>
                <RadioGroup
                  defaultValue={defaultOddsFormat}
                  value={oddsFormat || defaultOddsFormat}
                  onChange={changeOddsFormat}
                  name="radio-buttons-group"
                >
                  {odds_formats.map((format) => (
                    <Radio
                      disabled={!oddsEnabled}
                      value={format.value}
                      label={format.label}
                      color="neutral"
                    />
                  ))}
                </RadioGroup>
              </View>
            </>
          )}
          <ListDivider />
          <View style={{ paddingHorizontal: 12, paddingVertical: 8 }}>
            <FormLabel style={{ opacity: 0.5, marginBottom: 12 }}>
              Appearance
            </FormLabel>
            <RadioGroup
              defaultValue={"system"}
              value={appearanceSetting || "system"}
              onChange={changeAppearance}
              name="radio-buttons-group"
            >
              {appearance_options.map((format) => (
                <Radio
                  value={format.value}
                  label={format.label}
                  color="neutral"
                />
              ))}
            </RadioGroup>
          </View>
          {
            // <ListDivider />
            // <View style={{ paddingVertical: 8 }}>
            //   <View style={{ paddingHorizontal: 12 }}>
            //     <FormLabel style={{ opacity: 0.5, marginBottom: 8 }}>
            //       Download TNNS
            //     </FormLabel>
            //   </View>
            //   <MenuItem>Apple</MenuItem>
            //   <MenuItem>Android</MenuItem>
            // </View>
            // <ListDivider />
            // <View style={{ paddingVertical: 8 }}>
            //   <View style={{ paddingHorizontal: 12 }}>
            //     <FormLabel style={{ opacity: 0.5, marginBottom: 8 }}>
            //       Contact
            //     </FormLabel>
            //   </View>
            //   <MenuItem>Feedback</MenuItem>
            //   <MenuItem>Support</MenuItem>
            // </View>
          }
        </Menu>
      </Dropdown>
    </View>
  );
};

export default SettingsButton;
