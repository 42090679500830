import * as React from 'react';
import Main from './Main.js'
import { CssVarsProvider, extendTheme } from "@mui/joy/styles";

export default function WebApp() {
  const theme = extendTheme({
    colorSchemeSelector: 'media',
    fontFamily: {
      display: 'Inter-SemiBold',   // for headings
      body: 'Inter-Regular',       // for body text
      code: 'monospace',           // optional
    },
  });
  return(
    <CssVarsProvider theme={theme}>
    <Main/>
    </CssVarsProvider>
  )
}
