/* @flow */

import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  View,
  ActivityIndicator,
  Text,
  ScrollView,
  Image,
  Dimensions,
} from "react-native";

import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";

//Modules
import { Hoverable, Pressable } from "react-native-web-hover";

import NavBar from "../navigation/navbar copy.js";
import Footer from "../navigation/footer.js";

// import Ad from "../../components/ads/Ad.js";
import NavHelper from "./NavHelper.js";

import * as firebase from "firebase/app";
import { getRemoteConfig, fetchAndActivate } from "firebase/remote-config";

import MainStyles from "../../helpers/mainstyles.js";
import GLOBAL from "../../helpers/global.js";
import Functions from "../../helpers/functions.js";
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from "@react-navigation/native";

import getDomain from "../../helpers/getDomain.js";
import mixpanel from 'mixpanel-browser'

mixpanel.init('bff6acb8aaa8b703d04d2f3d07bebb72', {
  autocapture: false,
  cookie_expiration: 90
});

const Ad = React.lazy(() => import("../../components/ads/Ad.js"));

const purple = "rgb(128, 94, 255)";
const hoverColor = "rgba(128, 94, 255, 0.05)";
const moment = require("moment");

const BORDER_RADIUS = 8;

const firebaseConfig = {
  apiKey: "AIzaSyAVW64hdcykMWFtQqGD4hy3ahmHVATgn88",
  authDomain: "tennis-lwts.firebaseapp.com",
  projectId: "tennis-lwts",
  storageBucket: "tennis-lwts.appspot.com",
  messagingSenderId: "414782898270",
  appId: "1:414782898270:web:197a752cb2ed60a210f4cf",
  measurementId: "G-WVPSD2WN29",
};
// Initialize Firebase
if (!firebase.getApp()) {
  firebase.initializeApp(firebaseConfig);
}

const SPRTAccordion = ({ item, defaultOpen, renderItems, onExpandChange }) => {
  const [expanded, setExpanded] = useState(defaultOpen);

  useEffect(()=>{
    if(defaultOpen !== expanded) setExpanded(defaultOpen)
  },[defaultOpen])

  const changeExpanded = () => {
    if(onExpandChange) onExpandChange(item.title, !expanded)
    setExpanded(!expanded)
  }

  return (
    <View style={{ width: "100%" }}>
      <Pressable
        onPress={changeExpanded}
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          // backgroundColor: GLOBAL.style.dropdown,
          paddingVertical: 8,
          paddingHorizontal: 6,
          // borderRadius: 6,
          borderTopWidth: 1,
          borderTopColor: GLOBAL.style.content.card,
          // marginBottom: 6,
        }}
      >
        <Text
          style={{
            flex: 1,
            // opacity:0.75,
            fontSize: 12,
            color: GLOBAL.style.color,
            fontFamily: GLOBAL.fonts.semibold,
            paddingHorizontal: 0,
          }}
        >
          {item.title}
        </Text>
        <View
          style={{
            backgroundColor: GLOBAL.style.content.borderColor,
            padding: 4,
            justifyContent: "center",
            alignItems: "center",
            minWidth: 32,
            borderRadius: 6,
          }}
        >
          <Text
            style={{
              flex: 1,
              fontSize: 12,
              color: GLOBAL.style.color,
              fontFamily: GLOBAL.fonts.medium,
            }}
          >
            {item.data?.length}
          </Text>
        </View>
        <Image
          source={require("../../assets/icons/chevron-forward.png")}
          style={[
            {
              height: 12,
              width: 12,
              marginLeft: 12,
              resizeMode: "contain",
              tintColor: GLOBAL.style.color,
            },
            { transform: [{ rotate: expanded ? "-90deg" : "90deg" }] },
          ]}
        />
      </Pressable>
      {expanded && renderItems}
    </View>
  );
};

const Background = (props) => {
  const domain = getDomain();
  const __DEV__ = domain && domain?.includes("localhost");

  const [isFocused, setIsFocused] = useState(true);
  const [state, setState] = useState({
    loading: true,
    tournaments: GLOBAL?.tournaments || [],
    activeView: GLOBAL.activeViewTournaments || "All",
    videos: GLOBAL.videos || [],
    showSearch: false,
    tournaments_all: [],
    players_all: [],
    matches_all: [],
    hideColumn: props.hideColumn,
    tournaments: GLOBAL.tournaments || [],
    news: GLOBAL.news || [],
    titleStyle: {
      fontSize: 12,
      color: GLOBAL.style.color_font,
      fontFamily: GLOBAL.fonts.bold,
      letterSpacing: 1,
      textTransform: "uppercase",
      marginBottom: GLOBAL.padding / 2,
      paddingHorizontal: GLOBAL.padding / 4,
      opacity: 1,
    },
  });
  const route = useRoute();

  useEffect(()=>{
    // console.log(route)
    if(state?.config){
      //Get the accordion settings
      let config = getCachedData('config')
      if(!config){
        config = {
          toursOpened:{
            'ATP & WTA':true,
          }
        }
      }
      setState({...state, config})
    }
  },[route])

  // useFocusEffect(
  //   React.useCallback(() => {
  //     setIsFocused(true);
  //     return () => {
  //       setIsFocused(false);
  //     };
  //   }, [setIsFocused])
  // );

  const navigation = useNavigation();

  useEffect(() => {
    setup();
  }, []);

  const useList = useMemo(() => {
    const location = window?.location;

    const tnnsDomain =
      location?.host &&
      (location.host.includes("localhost") ||
        location.host.includes("tnnslive.com") ||
        location.host.includes("tnnswebtesting"));

    const isMobile = Dimensions.get("window").width < 900;

    // const scrollPath =
    //   // location.href.includes('/match/') ||
    //   location.href.includes('/tournament/') ||
    //   location.pathname === '/'

    const routesEnabled = [
      "Home",
      "Tournaments",
      "Tournament",
      "Match",
      "Player",
      "Ranking",
      "News",
      "Videos",
      "Podcasts",
    ];

    // console.log(isMobile, tnnsDomain, routesEnabled.includes(route?.name))

    return isMobile && tnnsDomain && routesEnabled.includes(route?.name);
  }, [route, isFocused]);

  useEffect(() => {
    GLOBAL.useList = useList;
  }, [useList]);

  const setup = async () => {
    GLOBAL.lockScroll = lockScroll;
    if (
      !domain ||
      (!domain.includes("tnnslive.com") &&
        !domain.includes("downloadtennis.com") &&
        !domain.includes("localhost") &&
        !domain.includes("tnnswebtesting"))
    )
      return;

    //Get the tournaments and news data
    let data_ops = [];
    if (!GLOBAL.isMobile) {
      data_ops = [
        async () => {
          return getData();
        },
        async () => {
          return updateUser();
        },
        async () => {
          return setupRemoteConfig();
        },
      ];
    } else {
      data_ops = [
        async () => {
          return updateUser();
        },
        async () => {
          return setupRemoteConfig();
        },
      ];
    }

    await Promise.all(data_ops.map(async (op) => op()));
  };

  const setupRemoteConfig = async () => {
    const app = firebase.getApp();
    if (!app) return;
    const remoteConfig = getRemoteConfig(app);
    const minFetchInterval = __DEV__ ? 0 : 1; // hours
    remoteConfig.settings.minimumFetchIntervalMillis =
      minFetchInterval * 60 * 60 * 1000;
    await fetchAndActivate(remoteConfig);
  };

  const getCachedData = useCallback((cacheRef) => {
    return (
      (localStorage.getItem(cacheRef) &&
        JSON.parse(localStorage.getItem(cacheRef))) ||
      null
    );
  }, []);

  const setCachedData = useCallback((cacheRef, update) => {
    return localStorage.setItem(
      cacheRef,
      typeof update === "object" ? JSON.stringify(update) : update
    );
  }, []);

  const updateUser = async () => {
    //Check cache
    const cacheRef = "geoip";
    const cacheUser = getCachedData(cacheRef);

    return fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then(async (data) => {
        const ip = data?.ip;

        if (!ip) return;

        //If missing, old or IP changed, update
        const refreshCutoff = moment().subtract(7, "days").valueOf();

        //Check if data is fresh enough
        if (
          cacheUser &&
          cacheUser.lastUpdated &&
          cacheUser.lastUpdated > refreshCutoff &&
          cacheUser.ip === ip
        )
          return;

        //Get the update
        const update = await Functions.tnnsAPI({
          url: "https://gen2-user-web-ysvbugl7mq-uc.a.run.app",
          ip,
        });

        if (!update?.location) return;

        const updateObj = {
          ...update.location,
          ip,
          lastUpdated: Date.now(),
        };

        //Save to cache
        return setCachedData(cacheRef, updateObj);
      })
      .catch((error) => console.error("Error fetching IP:", error));
  };

  const getData = async () => {
    const cacheRef = "secondaryContent";
    const cacheData = getCachedData(cacheRef);

    let data, usedCache;

    const refreshCutoff = moment().subtract(15, "minutes").valueOf();
    if (cacheData?.lastUpdated && cacheData.lastUpdated > refreshCutoff) {
      data = cacheData;
      usedCache = true;
    }

    if (!data) {
      //Call the API
      const data_r = await Functions.tnnsAPI({
        mode: "web_side_panels",
      });
      data = data_r?.data;
    }

    if (data) {
      let { news, tournaments, rankings, top_10 } = data;

      //Update cache
      if (!usedCache) {
        setCachedData(cacheRef, {
          ...data,
          lastUpdated: Date.now(),
        });
      }

      if (news) {
        news = news.slice(0, 3);
      }

      tournaments.forEach((obj) => {
        if (obj.title === "Other") obj.title = "UTR";
      });

      // tournaments = tournaments.filter(
      //   (obj) => obj.title !== "ITF" && obj.title !== "Other"
      // );

      GLOBAL.tournaments = tournaments;
      GLOBAL.news = news;
      GLOBAL.rankings = rankings;
      GLOBAL.top_10 = top_10;

      //Get the accordion settings
      let config = getCachedData('config')
      if(!config){
        config = {
          toursOpened:{
            'ATP & WTA':true,
          }
        }
      }

      // console.log('config', config)

      setState({
        ...state,
        loading: false,
        news,
        tournaments,
        rankings,
        top_10,
        config,
      });
    }
  };

  const download = () => {
    setState({ ...state, show_modal: true });
  };

  const lockScroll = (lockScroll) => {
    setState({ ...state, lockScroll });
  };

  const openPage = useCallback((page) => {
    GLOBAL.page = page;
    navigation.push(page);
  });

  //

  const renderContent = () => {
    return (
      <View
        style={[
          {
            flex: 1,
            width: "100%",
            backgroundColor: GLOBAL.style.content.backgroundColor,
          },
          MainStyles.flexStartCenter,
        ]}
      >
        {<NavHelper />}
        {renderSection()}
        {renderModal()}
      </View>
    );
  };

  const showModal = useCallback(() => {
    setState({ ...state, show_modal: false });
  });

  const renderModal = () => {
    var { show_modal } = state;

    var height = 540;

    if (show_modal) {
      return (
        <Pressable
          onPress={showModal}
          style={[
            {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.75)",
              zIndex: 9999999,
            },
            MainStyles.flexCenter,
            MainStyles.flexRow,
          ]}
        >
          <View
            style={[
              {
                width: 400,
                padding: GLOBAL.padding * 2,
                height,
                borderRadius: GLOBAL.borderRadius,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: GLOBAL.style.content.card,
              },
              MainStyles.flexCenterStart,
            ]}
          >
            <Text
              style={{
                fontFamily: GLOBAL.fonts.black,
                color: GLOBAL.style.color,
                fontSize: 24,
              }}
            >
              Come join the ultimate platform for tennis fans!
            </Text>
            <Text
              style={{
                fontFamily: GLOBAL.fonts.bold,
                color: GLOBAL.style.color,
                fontSize: 16,
                marginTop: GLOBAL.padding / 2,
                opacity: 0.5,
              }}
            >
              Built by fans, for fans
            </Text>

            <Text
              style={{
                fontFamily: GLOBAL.fonts.black,
                color: GLOBAL.style.color,
                fontSize: 14,
                marginTop: GLOBAL.padding * 2,
                textTransform: "uppercase",
                marginBottom: 4,
                opacity: 0.5,
              }}
            >
              KEY FEATURES
            </Text>
            <Text
              style={{
                fontFamily: GLOBAL.fonts.regular,
                color: GLOBAL.style.color,
                fontSize: 14,
                lineHeight: 24,
              }}
            >
              {
                "- Personalized news, scores and stats\n- Get notifications everytime your favorites play\n- Live chats during matches with other fans\n- Fantasy bracket competitions for every tour event\n- Orders of play in your timezone\n- Live PDF draws"
              }
            </Text>

            <Text
              style={{
                fontFamily: GLOBAL.fonts.regular,
                color: GLOBAL.style.color,
                fontSize: 14,
                marginTop: GLOBAL.padding * 2,
              }}
            >
              Download the TNNS app to find out why it's the highest rated app
              in tennis!
            </Text>

            <Text
              style={{
                fontFamily: GLOBAL.fonts.black,
                color: GLOBAL.style.color,
                fontSize: 14,
                marginTop: GLOBAL.padding * 2,
                textTransform: "uppercase",
                marginBottom: 4,
              }}
            >
              Download now!
            </Text>
            <View
              style={[
                { width: "100%" },
                MainStyles.flexRow,
                MainStyles.flexStartCenter,
              ]}
            >
              {renderDownloadButton("apple")}
              {renderDownloadButton("android")}
            </View>
          </View>
          <View
            style={[
              {
                height,
                width: 500,
                padding: GLOBAL.padding,
                backgroundColor: "#13074d",
                borderTopRightRadius: GLOBAL.borderRadius,
                borderBottomRightRadius: GLOBAL.borderRadius,
              },
              MainStyles.flexCenter,
            ]}
          >
            <Image
              style={{
                width: "100%",
                aspectRatio: 1,
                resizeMode: "contain",
                borderRadius: 20,
              }}
              source={require("../../assets/images/demosapp.webp")}
            />
          </View>
        </Pressable>
      );
    } else {
      return null;
    }
  };

  const renderDownloadButton = (app) => {
    var image = "";
    var url = "";
    if (app === "apple") {
      image = require("../../images/apple_app.png");
      url =
        "https://apps.apple.com/app/tnns-tennis-live-scores/id1547205724?platform=iphone";
    } else if (app === "android") {
      url = "https://play.google.com/store/apps/details?id=com.tennisrn";
      image = require("../../images/google_app.png");
    }

    return (
      <Hoverable style={{}}>
        {({ hovered }) => (
          <Pressable onPress={() => window.open(url, "_blank")}>
            <Image
              style={{
                height: 52,
                aspectRatio: 150 / 60,
                marginRight: GLOBAL.padding,
                resizeMode: "contain",
                opacity: hovered ? 0.8 : 1,
              }}
              source={image}
            />
          </Pressable>
        )}
      </Hoverable>
    );
  };

  const renderSection = () => {
    var { loading } = state;
    if (useList) {
      return (
        <>
          <NavBar
            navigation={navigation}
            page={props.page}
            onPressDownload={download}
          />
          {
            <View style={{ flex: 1, width: "100%" }}>
              <View
                style={[{ width: "100%", flex: 1 }, MainStyles.flexStartCenter]}
              >
                {props.hideContent ? (
                  props.children
                ) : (
                  <>
                    {
                      <View
                        style={[
                          {
                            width: "100%",
                            flex: 1,
                          },
                          MainStyles.flexCenter,
                        ]}
                      >
                        <View
                          style={[
                            {
                              flex: 1,
                              backgroundColor: GLOBAL.style.content.card,
                              overflow: "hidden",
                              width: "100%",
                            },
                            MainStyles.flexCenter,
                          ]}
                        >
                          {!props.title ? null : (
                            <View
                              style={{
                                width: "100%",
                                padding: GLOBAL.padding,
                                paddingBottom: 0,
                              }}
                            >
                              {renderHeader(props.title, props.icon)}
                            </View>
                          )}
                          {props.children}
                        </View>
                      </View>
                    }
                  </>
                )}
              </View>
            </View>
          }
        </>
      );
    }
    return (
      <>
        <NavBar
          navigation={navigation}
          page={props.page}
          onPressDownload={download}
        />
        {
          // props.hideContent ? props.children :
          <ScrollView
            style={{ flex: 1, width: "100%" }}
            contentContainerStyle={[
              {
                width: "100%",
                marginTop:
                  GLOBAL.displayMode === "web" ? GLOBAL.padding * 0.5 : 0,
              },
              MainStyles.flexStartCenter,
            ]}
          >
            <View style={[{ width: "100%" }, MainStyles.flexStartCenter]}>
              {props.hideContent ? (
                props.children
              ) : (
                <>
                  {
                    <View
                      style={[
                        {
                          width: "100%",
                          maxWidth: GLOBAL.maxWidth,
                          paddingTop: GLOBAL.isMobile ? 0 : GLOBAL.padding / 2,
                          backgroundColor: "transparent",
                          borderWidth: 0,
                          borderColor: GLOBAL.style.borderColor,
                        },
                        MainStyles.flexRow,
                        MainStyles.flexStartStart,
                      ]}
                    >
                      {/* {GLOBAL.displayMode === "web" ? renderLeftPanel() : null} */}
                      <View
                        style={[
                          {
                            flex: 1,
                            backgroundColor: props.noBackground
                              ? "transparent"
                              : GLOBAL.style.content.card,
                            borderWidth: props.noBackground ? 0 : 1,
                            borderRadius:
                              GLOBAL.displayMode !== "mobile"
                                ? BORDER_RADIUS
                                : 0,
                            borderColor: GLOBAL.style.borderColor,
                            overflow: "hidden",
                          },
                          MainStyles.flexCenter,
                        ]}
                      >
                        {!props.title ? null : (
                          <View
                            style={{
                              width: "100%",
                              padding: GLOBAL.padding,
                              paddingBottom: 0,
                            }}
                          >
                            {renderHeader(props.title, props.icon)}
                          </View>
                        )}
                        {props.children}
                      </View>
                      {GLOBAL.displayMode === "web" ? renderRightPanel() : null}
                    </View>
                  }
                  {GLOBAL.displayMode !== "web" && props.column ? (
                    <View
                      style={{
                        width: "100%",
                        marginTop: GLOBAL.padding,
                        borderWidth: 0,
                        backgroundColor: "transparent",
                        borderRadius: GLOBAL.borderRadius,
                        overflow: "hidden",
                        borderColor: GLOBAL.style.borderColor,
                      }}
                    >
                      {props.column}
                    </View>
                  ) : null}
                </>
              )}
            </View>
            {GLOBAL.isMobile || loading ? null : <Footer />}
          </ScrollView>
        }
      </>
    );
  };

  const renderLeftPanel = () => {
    const width = 240;
    return (
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          marginRight: 48,
        }}
      >
        <View
          style={{
            borderWidth: 1,
            borderLeftWidth: 1,
            width: width,
            paddingVertical: GLOBAL.padding,
            overflow: "hidden",
            minHeight: 200,
            backgroundColor: GLOBAL.style.content.card,
            // marginTop: GLOBAL.padding * 0,
            borderRadius: BORDER_RADIUS,
            borderColor: GLOBAL.style.borderColor,
            paddingBottom: 0,
          }}
        >
          {renderTournaments()}
        </View>
      </View>
    );
  };

  const renderRightPanel = () => {
    const width = 300;
    return (
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: 12,
        }}
      >
        {/* {props.rightPanel?.render && (
          <View
            style={{
              borderWidth: 1,
              borderLeftWidth: 1,
              width: width,
              // paddingVertical: GLOBAL.padding,
              overflow: "hidden",
              backgroundColor: GLOBAL.style.content.card,
              marginTop: GLOBAL.padding * 0,
              borderRadius: BORDER_RADIUS,
              borderColor: GLOBAL.style.borderColor,
              paddingBottom: 0,
              marginBottom: 12,
            }}
          >
            {props..render()}
            {renderButton(
              props.rightPanel?.button?.cta,
              null,
              props.rightPanel?.button?.onPress
            )}
          </View>
        )} */}

        {
          props.rightPanel?.render &&
          <Ad
          placement="Web_Sidebar"
          format="MEDIUM_RECTANGLE"
          adStyle={{ borderRadius: BORDER_RADIUS, overflow: "hidden" }}
          wrapperStyle={{ marginBottom: 12, width }}
        />
        }
        {(props.rightPanel?.render && (
          <View style={{ width }}>
            {props.rightPanel?.render()}
          </View>
        )) ||
          null}
        {
          <View
            style={{
              borderWidth: 1,
              borderLeftWidth: 1,
              width: width,
              paddingTop: GLOBAL.padding,
              overflow: "hidden",
              minHeight: 200,
              backgroundColor: GLOBAL.style.content.card,
              marginTop: GLOBAL.padding * 0,
              borderRadius: BORDER_RADIUS,
              borderColor: GLOBAL.style.borderColor,
              marginBottom: 12,
            }}
          >
            {renderTournaments()}
          </View>
        }
        {
          !props.rightPanel?.render &&
          <Ad
          placement="Web_Sidebar"
          format="MEDIUM_RECTANGLE"
          adStyle={{ borderRadius: BORDER_RADIUS, overflow: "hidden" }}
          wrapperStyle={{ marginBottom: 12, width }}
        />
        }
        {!props.rightPanel?.render && (
          <View
            style={{
              borderWidth: 1,
              borderLeftWidth: 1,
              width: width,
              paddingVertical: GLOBAL.padding,
              overflow: "hidden",
              minHeight: 200,
              backgroundColor: GLOBAL.style.content.card,
              // marginTop: GLOBAL.padding * 0,
              borderRadius: BORDER_RADIUS,
              borderColor: GLOBAL.style.borderColor,
              paddingBottom: 0,
              marginBottom: 12,
            }}
          >
            {renderNews()}
          </View>
        )}

        {renderAppPromo()}
      </View>
    );
  };

  const renderAppPromo = () => {
    return null;
    return (
      <View
        style={{
          // borderWidth: 1,
          // borderLeftWidth: 1,
          width: "100%",
          overflow: "hidden",
          minHeight: 100,
          // backgroundColor: GLOBAL.style.content.card,
          // marginTop: GLOBAL.padding * 0,
          borderRadius: BORDER_RADIUS,
          // borderColor: GLOBAL.style.borderColor,
          paddingBottom: 0,
          marginBottom: 12,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flex: 1,
            width: "100%",
            height: "100%",
            backgroundColor: purple,
            opacity: 0.1,
            position: "absolute",
          }}
        />
        <Text
          style={{ color: purple, fontFamily: "ClanOT-Bold", fontSize: 17 }}
        >
          App Promo
        </Text>
      </View>
    );
  };

  const renderTournaments = () => {
    var { tournaments } = state;
    return (
      <>
        <View style={{ width: "100%", paddingLeft: 16 }}>
          {renderHeader("Active tournaments", null && "Tournaments")}
        </View>
        {state.loading && tournaments.length === 0 ? (
          renderLoading()
        ) : (
          <View style={{ marginVertical: 6 }}>
            {tournaments.map((item, index) =>
              renderTournamentSection({ item, index })
            )}
          </View>
        )}
        {/* {renderButton("View all", "Tournaments")} */}
      </>
    );
  };

  const tapButton = (pageName, onPress) => {
    if (onPress) {
      onPress();
    } else if (pageName) {
      openPage(pageName);
    }
  };

  const renderButton = (string, pageName, onPress) => {
    return (
      <Pressable
        onPress={() => tapButton(pageName, onPress)}
        style={({ hovered }) => [
          {
            width: "100%",
            borderTopColor: GLOBAL.style.content.borderColor,
            borderTopWidth: 1,
            paddingHorizontal: 16,
            paddingVertical: 12,
            justifyContent: "center",
            alignItems: "center",
          },
          hovered && { backgroundColor: hoverColor },
        ]}
      >
        <Text
          style={{
            color: purple,
            fontSize: 13,
            fontFamily: "Inter-Bold",
            width: "100%",
            textAlign: "center",
          }}
        >
          {string}
        </Text>
      </Pressable>
    );
  };

  const onExpandChange = (title, expanded) => {
    let config = state?.config || {}
    config.toursOpened[title] = expanded
    setState({...state, config})
    setCachedData('config', config)
  }

  const renderTournamentSection = ({ item, index }) => {
    return (
      <View
        style={{
          paddingHorizontal: 8,
          marginVertical: 0,
          borderTopWidth: 1,
          borderTopColor: GLOBAL.style.content.borderColor,
        }}
      >
        <SPRTAccordion
          onExpandChange={onExpandChange}
          item={item}
          index={index}
          renderItems={item.data.map((item2, index2) =>
            renderTournament({ item: item2, index: index2 })
          )}
          defaultOpen={state?.config?.toursOpened && state?.config?.toursOpened[item.title]}
        />
      </View>
    );

    return (
      <View style={{ paddingHorizontal: 12 }}>
        <AccordionGroup size="sm" variant="outlined">
          <Accordion>
            <AccordionSummary>
              {item.title} [{item.data.length}]
            </AccordionSummary>
            <AccordionDetails>
              {item.data.map((item2, index2) =>
                renderTournament({ item: item2, index: index2 })
              )}
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      </View>
    );
    return (
      <View
        style={[
          {
            width: "100%",
            // marginBottom: index ? GLOBAL.padding : GLOBAL.padding / 4,
            marginTop: 0,
          },
          MainStyles.flexCenterStart,
        ]}
      >
        {
          <Text
            style={{
              fontFamily: GLOBAL.fonts.bold,
              paddingLeft: 16,
              color: GLOBAL.style.color,
              fontSize: 11,
              opacity: 0.67,
              textTransform: "uppercase",
              marginBottom: 2,
              marginTop: 12,
            }}
          >
            {item.title}
          </Text>
        }
        {item.data.map((item2, index2) =>
          renderTournament({ item: item2, index: index2 })
        )}
      </View>
    );
  };

  const openTournament = useCallback((item) => {
    navigation.push("Tournament", { id: item.id });
  });

  const renderTournamentNew = ({ item, index }) => {
    var logos = item.logos || [];

    return (
      <Hoverable style={{ width: "100%", paddingLeft: 12 }}>
        {({ hovered }) => (
          <Pressable
            onPress={() => openTournament(item)}
            style={[
              {
                width: "100%",
                paddingVertical: 12,
                paddingHorizontal: 6,
                paddingRight: 6,
                borderTopColor: GLOBAL.style.content?.borderColor,
                borderTopWidth: 1,
                backgroundColor: hovered
                  ? GLOBAL.style.dropdown
                  : "transparent",
                // borderRadius: GLOBAL.borderRadius,
              },
              MainStyles.flexRow,
              { justifyContent: "flex-start", alignItems: "center" },
            ]}
          >
            <View
              style={[
                { flex: 1, paddingRight: 0, flexDirection: "row" },
                MainStyles.flexStartCenter,
              ]}
            >
              <View
                style={{ alignItems: "flex-start", paddingRight: 8, flex: 1 }}
              >
                <Text
                  style={{
                    fontFamily: GLOBAL.fonts.regular,
                    color: GLOBAL.style.color,
                    fontSize: 12,
                    width: "100%",
                  }}
                >
                  {item.title}
                </Text>
                <Text
                  style={{
                    fontFamily: GLOBAL.fonts.thin,
                    color: GLOBAL.style.color,
                    fontSize: 11,
                    flex: 1,
                    opacity: 0.67,
                    marginTop: 4,
                  }}
                >
                  {item.subtitle_2}
                </Text>
              </View>

              <View
                style={{
                  height: 20,
                  justifyContent: "center",
                  borderRadius: 4,
                  overflow: "hidden",
                }}
              >
                <View
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "gold",
                    opacity: 0.1,
                  }}
                />
                <Text
                  style={{ color: "gold", fontSize: 11, paddingHorizontal: 4 }}
                >
                  1000
                </Text>
              </View>
            </View>
          </Pressable>
        )}
      </Hoverable>
    );
  };

  const renderTournament = ({ item, index }) => {
    var logos = item.logos || [];

    return (
      <Hoverable style={{ width: "100%" }}>
        {({ hovered }) => (
          <Pressable
            onPress={() => openTournament(item)}
            style={[
              {
                width: "100%",
                paddingVertical: 12,
                paddingHorizontal: 16,
                borderTopColor: GLOBAL.style.content.borderColor,
                borderTopWidth: index ? 1 : 0,
                backgroundColor: hovered ? "rgba(0,0,0,0.05)" : "transparent",
                // borderRadius: GLOBAL.borderRadius,
              },
              MainStyles.flexRow,
              { justifyContent: "flex-start", alignItems: "center" },
            ]}
          >
            <View style={[{ flex: 1 }, MainStyles.flexStartStart]}>
              <Text
                style={{
                  fontFamily: GLOBAL.fonts.medium,
                  color: GLOBAL.style.color,
                  fontSize: 12,
                  flex: 1,
                }}
              >
                {item.title}
              </Text>

              <Text
                style={{
                  fontFamily: GLOBAL.fonts.thin,
                  color: GLOBAL.style.color,
                  fontSize: 12,
                  textAlign: "left",
                  marginTop: 3,
                  opacity: 0.5,
                }}
              >
                {item.subtitle_2}
              </Text>
            </View>

            {logos.length === 0 ? null : (
              <View
                style={[
                  {
                    marginLeft: 16,
                    marginLeft: 0,
                    // marginTop: 2.5,
                  },
                  MainStyles.flexCenter,
                ]}
              >
                {logos.map((item2, index2) =>
                  renderTournamentLogo({ item: item2, index: index2 })
                )}
              </View>
            )}
          </Pressable>
        )}
      </Hoverable>
    );
  };

  const renderTournamentLogo = ({ item }) => {
    return (
      <Image
        style={{
          width: 24,
          marginVertical: 2,
          aspectRatio: item.aspectRatio,
          resizeMode: "contain",
        }}
        source={{ uri: item.url }}
      />
    );
  };

  const renderNews = () => {
    var { news } = state;
    return (
      <View style={{ width: "100%", marginTop: GLOBAL.padding * 0 }}>
        <View
          style={{
            width: "100%",
            paddingHorizontal: 16,
            paddingBottom: 8,
            borderBottomWidth: 1,
            borderBottomColor: GLOBAL.style.content.borderColor,
          }}
        >
          {renderHeader("Latest headlines", null && "News")}
        </View>
        {state.loading && news.length === 0
          ? renderLoading()
          : news.map((item, index) => renderNewsItem({ item, index }))}
        {renderButton("View more", "News")}
      </View>
    );
  };

  const openNewsItem = useCallback((item) => {
    window.open(item.url, "_blank");
  });

  const renderNewsItem = ({ item, index }) => {
    var logo = item.image;
    var size = 14;

    if (index === 0) {
      return (
        <Hoverable style={{ width: "100%" }}>
          {({ hovered }) => (
            <Pressable
              onPress={() => openNewsItem(item)}
              style={[
                {
                  width: "100%",
                  paddingBottom: 12,
                  // paddingHorizontal: 16,
                  // marginLeft: -GLOBAL.padding / 1.5,
                  borderTopColor: GLOBAL.style.content.borderColor,
                  borderTopWidth: index > 0 ? 1 : 0,
                  backgroundColor: hovered ? "rgba(0,0,0,0.05)" : "transparent",
                  // borderRadius: GLOBAL.borderRadius,
                },
                MainStyles.flexStartStart,
              ]}
            >
              {
                <Image
                  style={{
                    width: "100%",
                    aspectRatio: 1920 / 1080,
                    // borderRadius: BORDER_RADIUS,
                    overflow: "hidden",
                    resizeMode: "cover",
                    opacity: 1,
                  }}
                  source={{ uri: logo }}
                  // source={require('../../assets/icons/news0.png')}
                />
              }

              <View
                onPress={() => openNewsItem(item)}
                style={[
                  {
                    width: "100%",
                    padding: GLOBAL.padding / 1.5,
                    paddingVertical: GLOBAL.padding / 1.5,
                    paddingBottom: 0,
                    marginLeft: -GLOBAL.padding / 1.5,
                    borderTopColor: GLOBAL.style.content.borderColor,
                    borderTopWidth: index > 0 ? 1 : 0,
                    borderRadius: GLOBAL.borderRadius,
                  },
                  MainStyles.flexRow,
                  MainStyles.flexStartStart,
                ]}
              >
                <View
                  style={[
                    { flex: 1, paddingHorizontal: 12 },
                    MainStyles.flexStartStart,
                  ]}
                >
                  <Text
                    style={{
                      fontFamily: GLOBAL.fonts.regular,
                      color: GLOBAL.style.color,
                      fontSize: 12,
                      lineHeight: 18,
                      flex: 1,
                    }}
                  >
                    {item.text}
                  </Text>
                  <Text
                    style={{
                      fontFamily: GLOBAL.fonts.regular,
                      color: GLOBAL.style.color,
                      fontSize: 11,
                      textAlign: "left",
                      marginTop: GLOBAL.padding / 4,
                      opacity: 0.5,
                    }}
                  >
                    {item.header}
                  </Text>
                </View>
              </View>
            </Pressable>
          )}
        </Hoverable>
      );
    } else {
      return (
        <Hoverable style={{ width: "100%" }}>
          {({ hovered }) => (
            <Pressable
              onPress={() => openNewsItem(item)}
              style={[
                {
                  width: "100%",
                  paddingHorizontal: 12,
                  paddingVertical: 12,
                  // marginLeft: -GLOBAL.padding / 1.5,
                  borderTopColor: GLOBAL.style.content.borderColor,
                  borderTopWidth: index > 0 ? 1 : 0,
                  backgroundColor: hovered ? "rgba(0,0,0,0.05)" : "transparent",
                  // borderRadius: GLOBAL.borderRadius,
                },
                MainStyles.flexRow,
                MainStyles.flexStartStart,
              ]}
            >
              <View style={[{ flex: 1 }, MainStyles.flexStartStart]}>
                <Text
                  style={{
                    fontFamily: GLOBAL.fonts.regular,
                    color: GLOBAL.style.color,
                    fontSize: 12,
                    lineHeight: 18,
                    flex: 1,
                  }}
                >
                  {item.text}
                </Text>
                <Text
                  style={{
                    fontFamily: GLOBAL.fonts.regular,
                    color: GLOBAL.style.color,
                    fontSize: 11,
                    textAlign: "left",
                    marginTop: GLOBAL.padding / 4,
                    opacity: 0.5,
                  }}
                >
                  {item.header}
                </Text>
              </View>
            </Pressable>
          )}
        </Hoverable>
      );
    }
  };

  const renderNewsItemOld = ({ item, index }) => {
    var logo = item.image;
    var size = 14;

    if (index === 0) {
      return (
        <Hoverable style={{ width: "100%" }}>
          {({ hovered }) => (
            <Pressable
              onPress={() => openNewsItem(item)}
              style={[
                {
                  width: "100%",
                  paddingVertical: 12,
                  paddingHorizontal: 16,
                  // marginLeft: -GLOBAL.padding / 1.5,
                  borderTopColor: GLOBAL.style.content.borderColor,
                  borderTopWidth: index > 0 ? 1 : 0,
                  backgroundColor: hovered ? "rgba(0,0,0,0.05)" : "transparent",
                  // borderRadius: GLOBAL.borderRadius,
                },
                MainStyles.flexStartStart,
              ]}
            >
              {
                <Image
                  style={{
                    width: "100%",
                    aspectRatio: 1920 / 1080,
                    borderRadius: BORDER_RADIUS,
                    overflow: "hidden",
                    resizeMode: "cover",
                    opacity: 1,
                  }}
                  source={{ uri: logo }}
                  // source={require('../../assets/icons/news0.png')}
                />
              }

              <View
                onPress={() => openNewsItem(item)}
                style={[
                  {
                    width: "100%",
                    padding: GLOBAL.padding / 1.5,
                    paddingVertical: GLOBAL.padding / 1.5,
                    paddingBottom: 0,
                    marginLeft: -GLOBAL.padding / 1.5,
                    borderTopColor: GLOBAL.style.content.borderColor,
                    borderTopWidth: index > 0 ? 1 : 0,
                    borderRadius: GLOBAL.borderRadius,
                  },
                  MainStyles.flexRow,
                  MainStyles.flexStartStart,
                ]}
              >
                {!logo ? null : (
                  <Image
                    style={{
                      width: size,
                      height: size,
                      borderRadius: 4,
                      overflow: "hidden",
                      marginRight: GLOBAL.padding / 1.5,
                      resizeMode: "cover",
                      tintColor: "black",
                      opacity: 0.35,
                    }}
                    // source={{uri:logo}}
                    source={require("../../assets/icons/news0.png")}
                  />
                )}

                <View style={[{ flex: 1 }, MainStyles.flexStartStart]}>
                  <Text
                    style={{
                      fontFamily: GLOBAL.fonts.regular,
                      color: GLOBAL.style.color,
                      fontSize: 11,
                      textAlign: "left",
                      marginBottom: GLOBAL.padding / 4,
                      opacity: 0.5,
                    }}
                  >
                    {item.header}
                  </Text>
                  <Text
                    style={{
                      fontFamily: GLOBAL.fonts.regular,
                      color: GLOBAL.style.color,
                      fontSize: 12,
                      lineHeight: 18,
                      flex: 1,
                    }}
                  >
                    {item.text}
                  </Text>
                </View>
              </View>
            </Pressable>
          )}
        </Hoverable>
      );
    } else {
      return (
        <Hoverable style={{ width: "100%" }}>
          {({ hovered }) => (
            <Pressable
              onPress={() => openNewsItem(item)}
              style={[
                {
                  width: "100%",
                  paddingHorizontal: 16,
                  paddingVertical: 12,
                  // marginLeft: -GLOBAL.padding / 1.5,
                  borderTopColor: GLOBAL.style.content.borderColor,
                  borderTopWidth: index > 0 ? 1 : 0,
                  backgroundColor: hovered ? "rgba(0,0,0,0.05)" : "transparent",
                  // borderRadius: GLOBAL.borderRadius,
                },
                MainStyles.flexRow,
                MainStyles.flexStartStart,
              ]}
            >
              {!logo ? null : (
                <Image
                  style={{
                    width: size,
                    height: size,
                    borderRadius: 4,
                    overflow: "hidden",
                    marginRight: GLOBAL.padding / 1.5,
                    resizeMode: "cover",
                    tintColor: "black",
                    opacity: 0.35,
                  }}
                  // source={{uri:logo}}
                  source={require("../../assets/icons/news0.png")}
                />
              )}

              <View style={[{ flex: 1 }, MainStyles.flexStartStart]}>
                <Text
                  style={{
                    fontFamily: GLOBAL.fonts.regular,
                    color: GLOBAL.style.color,
                    fontSize: 11,
                    textAlign: "left",
                    marginBottom: GLOBAL.padding / 4,
                    opacity: 0.5,
                  }}
                >
                  {item.header}
                </Text>
                <Text
                  style={{
                    fontFamily: GLOBAL.fonts.regular,
                    color: GLOBAL.style.color,
                    fontSize: 12,
                    lineHeight: 18,
                    flex: 1,
                  }}
                >
                  {item.text}
                </Text>
              </View>
            </Pressable>
          )}
        </Hoverable>
      );
    }
  };

  const renderHeader = (title, view_all) => {
    return (
      <View
        style={[
          { width: "100%", marginBottom: 6, marginTop: -4 },
          MainStyles.flexRow,
          MainStyles.flexBetweenCenter,
        ]}
      >
        <Text
          style={{
            // fontSize: 16,
            // color: GLOBAL.style.color,
            // fontFamily: GLOBAL.fonts.bold,
            ...GLOBAL.typography.cardTitle,
            flex: 1,
          }}
        >
          {title}
        </Text>
        {!view_all ? null : (
          <Hoverable style={{}}>
            {({ hovered }) => (
              <Pressable
                onPress={() => openPage(view_all)}
                style={{ paddingHorizontal: GLOBAL.padding }}
              >
                <Text
                  style={{
                    fontFamily: GLOBAL.fonts.bold,
                    color: hovered
                      ? GLOBAL.colors.button_hover
                      : GLOBAL.colors.button,
                    fontSize: 11,
                    flex: 1,
                  }}
                >
                  View all
                </Text>
              </Pressable>
            )}
          </Hoverable>
        )}
      </View>
    );
  };

  const renderLoading = () => {
    return (
      <View style={[{ width: "100%", height: 200 }, MainStyles.flexCenter]}>
        <ActivityIndicator size="small" color="black" />
      </View>
    );
  };

  return renderContent();
};

export default memo(Background);
