/* @flow */

import React, { PureComponent } from "react";
import { View, Text, Pressable } from "react-native";

//Modules

//Components

//Helpers
import MainStyles from "../../helpers/mainstyles.js";
import GLOBAL from "../../helpers/global.js";
import { Hoverable } from "react-native-web-hover";

export default class MyComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item,
      index: this.props.index,
    };
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.item !== this.props.item) {
      this.setState({ item: this.props.item });
    }
    if (prevProps.index !== this.props.index) {
      this.setState({ index: this.props.index });
    }
  };

  renderInside = (type, style, value) => {
    var render = this.renderNormal(style);
    if (type === "past_meeting") {
      render = this.renderMeeting(style);
    } else if (value) {
      render = this.renderSingleValue(style);
    }

    return render;
  };

  renderValue = ({ value, style, flex }) => {
    return <Text style={[style, { flex }]}>{value}</Text>;

    let string = value;
    let side_value;
    if (typeof value === "string" && value.includes("% (")) {
      side_value = value.split("% (")[1].replace(")", "");
      string = value.split(" (")[0];
    }

    return (
      <View
        style={{
          flex,
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          paddingRight: 12,
        }}
      >
        <Text style={[style, { flex: 1 }]}>{string}</Text>
        {side_value && (
          <Text style={[style, { fontSize: 11, opacity: 0.6, marginLeft: 2 }]}>
            {side_value}
          </Text>
        )}
      </View>
    );
  };

  renderNormal = (style) => {
    var { item, index } = this.state;
    var { title, values, type } = item;
    var { mode } = this.props;

    var flex = 0.5;
    if (type === "bios") {
      flex = 0.25;
    }

    if (mode === "match_bios") {
      return (
        <>
          <Text style={[style, { flex: 0.33, opacity: 0.67 }]}>
            {item?.title}
          </Text>

          {!values[0] && values[0] !== 0 ? null : (
            <Text style={[style, { flex: 0.33 }]}>{values[0]}</Text>
          )}

          {!values[1] && values[1] !== 0 ? null : (
            <Text style={[style, { flex: 0.33 }]}>{values[1]}</Text>
          )}
        </>
      );
    } else {
      // return (
      //   <>
      //     <Text style={[style, { flex: 1 }]}>{item?.title}</Text>

      //     {!values ? null : (
      //       <>
      //         {!values[0] && values[0] !== 0 ? null : (
      //           <View style={{ flex, alignItems: "flex-start" }}>
      //             <Text
      //               style={[
      //                 style,
      //                 { flex: 1, fontSize: 13, fontFamily: "Inter-Medium" },
      //               ]}
      //             >
      //               {values[0].split("(")[0]}
      //             </Text>
      //             <Text
      //               style={[
      //                 style,
      //                 { flex: 1, fontSize: 11, opacity: 0.5, marginTop: 4 },
      //               ]}
      //             >
      //               {values[0].split("(")[1].replace(")", "")}
      //             </Text>
      //           </View>
      //         )}

      //         {!values[1] && values[1] !== 0 ? null : (
      //           <View style={{ flex, alignItems: "flex-start" }}>
      //             <Text
      //               style={[
      //                 style,
      //                 { flex: 1, fontSize: 13, fontFamily: "Inter-Medium" },
      //               ]}
      //             >
      //               {values[1].split("(")[0]}
      //             </Text>
      //             <Text
      //               style={[
      //                 style,
      //                 { flex: 1, fontSize: 11, opacity: 0.5, marginTop: 4 },
      //               ]}
      //             >
      //               {values[1].split("(")[1].replace(")", "")}
      //             </Text>
      //           </View>
      //         )}
      //       </>
      //     )}
      //   </>
      // );

      return (
        <>
          <Text style={[style, { flex: 1, opacity: 0.75 }]}>{item?.title}</Text>

          {!values ? null : (
            <>
              {
                !values[0] && values[0] !== 0
                  ? null
                  : this.renderValue({ value: values[0], style, flex })
                // (
                //   <Text style={[style, { flex }]}>{values[0]}</Text>
                // )
              }

              {
                !values[1] && values[1] !== 0
                  ? null
                  : this.renderValue({ value: values[1], style, flex })
                // (
                //   <Text style={[style, { flex }]}>{values[1]}</Text>
                // )
              }
            </>
          )}
        </>
      );
    }
  };

  renderSingleValue = (style) => {
    var { item, index } = this.state;
    return (
      <>
        <Text style={[style, { flex: 1, opacity: 0.67 }]}>{item?.title}</Text>

        <Text style={[style, { flex: 1, textAlign: "right" }]}>
          {item.value}
        </Text>
      </>
    );
  };

  openMeeting = (id) => {
    GLOBAL.navigation.push('Match', {id})
  };

  renderMeeting = (style) => {
    var { item, index } = this.state;
    var { title, values, type } = item;
    const id = item?.id_match && item?.id_match.split(":")[2];

    if (!values) {
      return null;
    } else {
      return (
        <Hoverable style={{flex:1}}>
          {({ hovered }) => (
            <Pressable onPress={()=>this.openMeeting(id)} style={[{ width: "100%", backgroundColor:hovered ? GLOBAL.style.dropdown : 'transparent' }, MainStyles.flexCenter]}>
              <View
                style={[
                  { width: "100%" },
                  MainStyles.flexRow,
                  MainStyles.flexBetweenCenter,
                ]}
              >
                <Text style={[style, { flex: 1 }]} numberOfLines={1}>
                  {values[0]}
                </Text>
                <Text style={[style, {}]}>{values[1]}</Text>
              </View>
              <View
                style={[
                  {
                    width: "100%",
                    marginTop: GLOBAL.padding / 2,
                    opacity: 0.5,
                  },
                  MainStyles.flexRow,
                  MainStyles.flexBetweenCenter,
                ]}
              >
                <Text style={[style, { flexShrink: 1 }]} numberOfLines={1}>
                  {values[2]}
                </Text>
                <Text style={[style, {}]}>{values[3]}</Text>
              </View>
            </Pressable>
          )}
        </Hoverable>
      );
    }
  };

  render = () => {
    var { item, index } = this.state;

    var { title, values, type, value } = item;
    var backgroundColor =
      index % 2 ? GLOBAL.style.background_alternating : "transparent";

    var flex = 0.5;
    if (type === "bios") {
      flex = 0.25;
    }

    var style = {
      fontSize: 13,
      textAlign: "left",
      flexWrap: "wrap",
      fontFamily: GLOBAL.fonts.regular,
      color: GLOBAL.style.color,
      paddingRight: 4,
    };

    // console.log(values, value)

    try {
      return (
        <View
          style={[
            {
              width: "100%",
              paddingHorizontal: GLOBAL.padding,
              paddingVertical: GLOBAL.padding / 1.5,
              backgroundColor: "transparent",
              borderTopColor: GLOBAL.style.content?.borderColor,
              borderTopWidth: index > 0 ? 1 : 0,
            },
            MainStyles.flexRow,
            MainStyles.flexBetweenCenter,
          ]}
        >
          {this.renderInside(type, style, value)}
        </View>
      );
    } catch (e) {
      return null;
    }
  };
}
