/* @flow */

import React, { PureComponent } from "react";
import { View, Text, Pressable, Image, SectionList } from "react-native";

//Modules

//Components
import Loading from "../../components/general/loading.js";
import Tabs from "../../components/general/tabs.js";
import Icon from "../../components/general/icon.js";
import SectionHeader from "../../components/general/sectionheader.js";
import SectionItem from "../../components/general/sectionitem.js";

//Helpers
import MainStyles from "../../helpers/mainstyles.js";
import Functions from "../../helpers/functions.js";
import GLOBAL from "../../helpers/global.js";

export default class MyComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sections: [],
      loading: true,
      refreshing: true,
      format: "all",
      tabs: [],
      abbreviations: this.props.abbreviations,
    };
  }

  componentDidMount = async () => {
    this.setup();
    // var voted = await Functions.getAsyncData('votes/'+this.props.id)
    // this.setState({voted})
  };

  componentWillUnmount = async () => {};

  setup = async (params) => {
    var { id, formats } = this.props;

    this.setState({ refreshing: true });

    if (!params) {
      params = {};
    }

    params.id = id;
    params.mode = "match_info";
    params.submode = "preview";

    // // params.url = 'http://127.0.0.1:5001/tennis-lwts/us-central1/gen2_web_api'
    // params.url = 'https://gen2-match-info-web-ysvbugl7mq-uc.a.run.app'

    var data_r = await Functions.tnnsAPI(params);
    var data = data_r?.data || {};

    var { sections } = data;

    this.setState({ sections, loading: false, refreshing: false });
  };

  openTab = (tab) => {
    // GLOBAL.navigation.navigate(tab, {scrollToH2H:true})
    this.props.forceTab(tab);
  };

  vote = async (index) => {
    var { id } = this.props;

    if (this.state.voted !== 0 && this.state.voted !== 1) {
      this.setState({ voted: index });

      // await firebase.firestore()
      // .collection('votes_sr')
      // .add({
      //   id,
      //   index,
      //   userId: GLOBAL.user.uid,
      //   createdAt: new Date(),
      // })
      // .catch(e => {
      // })
      //
      // await Functions.setAsyncData('votes/'+id, index)
      // await Functions.amplitudeEvent('Voted on Match Predictor')
    }
  };

  //Renders
  renderContent = () => {
    var { sections } = this.state;
    return (
      <View
        style={{ width: "100%", flex: 1 }}
        animation={"fadeIn"}
        duration={240}
      >
        <SectionList
          style={{ width: "100%", flex: 1 }}
          contentContainerStyle={{ width: "100%", paddingBottom: 0 }}
          sections={sections || []}
          keyExtractor={(item, index) => index}
          renderItem={({ item, index, section }) =>
            this.renderItem({ item, index, section })
          }
          renderSectionHeader={this.renderSectionHeader}
          onScrollBeginDrag={this.props.onScroll}
          initialNumToRender={20}
          stickySectionHeadersEnabled={!!this.props.listHeader}
          ListHeaderComponent={this.props.listHeader}
        />
      </View>
    );
  };

  renderItem = ({ item, index, section }) => {
    // return <SectionItem item={item} index={index}/>

    var { id, button, button_index } = section;
    var render = null;
    if (id === "vote") {
      render = this.renderVote({ item, index });
    } else if (id === "h2h") {
      render = this.renderH2H({ item, index });
    } else if (id === "last_meetings") {
      render = this.renderMeeting({ item, index });
    } else if (id === "form") {
      render = this.renderForm({ item, index });
    } else if (id === "game_info" || id === "howtowatch" || id === "info_v2") {
      render = this.renderInfo({ item, index });
    }

    return (
      <View style={[{ width: "100%" }, MainStyles.flexCenter]}>
        {render}
        {!button || button_index !== index
          ? null
          : this.renderTabButton(button)}
      </View>
    );
  };

  renderSectionHeader = ({ section }) => {
    var { title, type, subtitle } = section;
    return <SectionHeader title={title} subtitle={subtitle} />;
  };

  //Modes
  renderVote = ({ item, index }) => {
    return (
      <View
        style={[
          { width: "100%", marginTop: -1 },
          MainStyles.flexRow,
          MainStyles.flexStartCenter,
        ]}
      >
        {item.map((item2, index2) => this.renderVoteItem(item2, index2))}
      </View>
    );
  };

  renderVoteItem = (item, index) => {
    var { voted } = this.state;
    var showBorder = index === 0;
    var showVotes = voted === 0 || voted === 1;
    var votedFor = voted === index;
    var backgroundColor =
      votedFor || !showVotes
        ? GLOBAL.style.content?.borderColor
        : GLOBAL.style.content?.borderColor;
    var opacity = 1;

    return (
      <Pressable
        onPress={() => this.vote(index)}
        style={[
          {
            width: "50%",
            overflow: "hidden",
            padding: 0,
            borderRightColor: GLOBAL.style.content?.borderColor,
            borderRightWidth: showBorder ? 1 : 0,
          },
          MainStyles.flexBetweenCenter,
          MainStyles.flexRow,
        ]}
      >
        <View
          style={[
            {
              flex: 1,
              width: "100%",
              borderRadius: 0,
              backgroundColor: "transparent",
              borderColor: GLOBAL.style.content?.borderColor,
              borderWidth: 0,
              height: 54,
              overflow: "hidden",
            },
            MainStyles.flexRow,
            MainStyles.flexBetweenCenter,
          ]}
        >
          {!showVotes ? (
            <View
              style={{
                position: "absolute",
                left: 0,
                height: "100%",
                width: "100%",
                backgroundColor: GLOBAL.style.secondaryColor,
                opacity: 0,
              }}
            />
          ) : (
            <View
              style={{
                position: "absolute",
                left: 0,
                height: "100%",
                width: item.value,
                backgroundColor: GLOBAL.style.accent,
                opacity: 0.2,
                zIndex: 1,
              }}
            />
          )}
          <Text
            style={{
              fontFamily: GLOBAL.fonts.bold,
              fontSize: 13,
              color: GLOBAL.style.color,
              textTransform: "uppercase",
              paddingLeft: GLOBAL.padding / 1,
              flex: 1,
              zIndex: 2,
            }}
            numberOfLines={1}
          >
            {item.name}
          </Text>
          {!showVotes ? null : (
            <Text
              animation="fadeIn"
              style={{
                fontFamily: votedFor
                  ? GLOBAL.fonts.medium
                  : GLOBAL.fonts.regular,
                fontSize: 13,
                color: GLOBAL.style.color,
                paddingRight: GLOBAL.padding / 2,
                zIndex: 2,
              }}
            >
              {item.value}
            </Text>
          )}
        </View>
      </Pressable>
    );
  };

  renderH2H = ({ item, index }) => {
    return (
      <View
        style={[
          { width: "100%" },
          MainStyles.flexRow,
          MainStyles.flexStartCenter,
        ]}
      >
        {item.map((item2, index2) => this.renderH2HItem(item2, index2))}
      </View>
    );
  };

  renderH2HItem = (item, index) => {
    var size = 96;
    var showBorder = index === 0;
    var images = item?.player?.image || [];
    return (
      <View
        style={[
          {
            width: "50%",
            overflow: "hidden",
            borderRightWidth: showBorder ? 1 : 0,
            borderRightColor: GLOBAL.style.content?.borderColor,
            paddingHorizontal: GLOBAL.padding,
            paddingVertical: GLOBAL.padding / 1.5,
          },
          MainStyles.flexCenterStart,
        ]}
      >
        <Text
          style={{
            fontFamily: GLOBAL.fonts.regular,
            color: GLOBAL.style.color_secondary,
            fontSize: 13,
          }}
        >
          {item?.title}
        </Text>

        <Text
          style={{
            fontFamily: GLOBAL.fonts.black,
            color: GLOBAL.style.color,
            fontSize: 26,
            marginTop: GLOBAL.padding / 1.6,
          }}
        >
          {item?.value}
        </Text>

        <Text
          style={{
            fontFamily: GLOBAL.fonts.regular,
            color: GLOBAL.style.color_secondary,
            fontSize: 13,
            marginTop: 2,
          }}
        >
          {item?.player?.name}
        </Text>

        {images.length !== 1 ? null : (
          <Image
            style={[
              {
                height: size,
                width: size,
                position: "absolute",
                right: -size * 0.1,
                bottom: -size * 0.1,
              },
            ]}
            source={{ uri: images[0] }}
          />
        )}
      </View>
    );
  };

  renderMeeting = ({ item, index }) => {
    return <SectionItem item={item} index={index} />;
  };

  //FORM
  renderForm = ({ item, index, button }) => {
    return item.map((item2, index2) => this.renderFormItem(item2, index2));
  };

  renderFormItem = (item, index) => {
    var backgroundColor =
      index % 2 ? GLOBAL.style.background_alternating : "transparent";
    var size = 48;
    if (item) {
      return (
        <View
          style={[
            {
              width: "100%",
              overflow: "hidden",
              paddingHorizontal: GLOBAL.padding,
              paddingVertical: GLOBAL.padding / 1.5,
              backgroundColor: "transparent",
              borderTopColor: GLOBAL.style.content?.borderColor,
              borderTopWidth: index > 0 ? 1 : 0,
            },
            MainStyles.flexStartCenter,
            MainStyles.flexRow,
          ]}
        >
          <View
            style={[
              { flex: 1, paddingRight: GLOBAL.padding, paddingLeft: 0 },
              MainStyles.flexCenterStart,
            ]}
          >
            <Text
              style={{
                fontSize: 12,
                fontFamily: GLOBAL.fonts.regular,
                color: GLOBAL.style.color_secondary,
              }}
              numberOfLines={1}
            >
              {item.title || ""}
            </Text>
            <Text
              style={{
                fontSize: 14,
                fontFamily: GLOBAL.fonts.bold,
                color: GLOBAL.style.color,
              }}
              numberOfLines={1}
            >
              {item.subtitle || ""}
            </Text>
          </View>

          <View
            style={[{ flex: 1 }, MainStyles.flexRow, MainStyles.flexEndCenter]}
          >
            {item.results?.map((item2, index2) =>
              this.renderFormResult(item2, index2)
            )}
          </View>
        </View>
      );
    } else {
      return null;
    }
  };

  renderFormResult = (item, index) => {
    var size = 24;
    var backgroundColor = item === "W" ? "#28B35A" : GLOBAL.colors.pink;
    return (
      <View
        style={[
          {
            width: size,
            height: size,
            borderRadius: 4,
            overflow: "hidden",
            marginLeft: 6,
          },
          MainStyles.flexCenter,
        ]}
      >
        <View
          style={{
            width: "100%",
            height: "100%",
            backgroundColor,
            position: "absolute",
            opacity: 0.2,
          }}
        />
        <Text
          style={{
            color: backgroundColor,
            fontSize: 12,
            fontFamily: GLOBAL.fonts.black,
          }}
        >
          {item}
        </Text>
      </View>
    );
  };

  renderInfo = ({ item, index }) => {
    return <SectionItem item={item} index={index} />;
  };

  renderTabButton = ({ hide, tab, title }) => {
    if (hide) {
      return null;
    } else {
      return null;
    }
  };

  render = () => {
    var { loading } = this.state;

    try {
      return (
        <View
          style={[
            { flex: 1, width: "100%", minHeight: 100 },
            MainStyles.flexStartCenter,
          ]}
        >
          {(loading && this.props.listHeader) || null}
          {loading ? <Loading /> : this.renderContent()}
        </View>
      );
    } catch (e) {
      return null;
    }
  };
}
